<template>
  <div v-if="bottomBannerLIst.length !== 0">
    <div
      class="uren-banner_area uren-banner_area-4"
      style="background-color: #ffffff; padding: 25px 0px 70px 0px"
    >
      <div class="container-fluid">
        <div class="row">
          <div
            class="col-md-6"
            v-for="banner in bottomBannerLIst"
            :key="banner.teid"
          >
            <div>
              <a href="#"><img :src="path+banner.imageUrl" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from "@/config.js";
// import userService from "@/services/user.service";
export default {
  name: "MainSlider",
  props: {
    bottomBannerLIst: Array,
  },

  data() {
    return {
      path: Config.BASE_URL_IMG,

      Mainbannerlist: "",
    };
  },
  // mounted() {
  //   this.getBanners();
  // },

  methods: {
    getBanners() {
      this.Mainbannerlist = [
        {
          Link: "Auction",
          elementName: "Web Home Banner Slider (1920x695)",
          imageUrl: "assets/images/yellow-banner.png",
          mob_type: null,
          mob_urlKey: null,
          noofslides: 1,
          position: 1,
          teid: 82,
        },
        {
          Link: "Auction",
          elementName: "Web Home Banner Slider (1920x695)",
          imageUrl: "assets/images/blue-banner.png",
          mob_type: null,
          mob_urlKey: null,
          noofslides: 1,
          position: 2,
          teid: 83,
        },
      ];

      // userService
      //   .getBanners()
      //   .then((response) => {
      //     this.Mainbannerlist = response.data.Data;
      //     console.log(response.data.Data[0]);
      //     // for (let index = 0; index < this.Mainbannerlist.length; index++) {
      //     //   const element = this.Mainbannerlist[index];
      //     //   console.log(element.imageUrl);
      //     // }
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    },
  },
};
</script>
