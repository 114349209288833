<template>
  <div class="sidenav">
      <ul>
          <li><a href="/">Home</a></li>
          <li v-if="userRole != 'Vendor'"><a href="/auction">Auctions</a></li>
          <li><a href="wishlist" v-if="userRole == 'Customer'">Whistlist</a></li>
          <li><a href="customerprofile"  v-if="userRole == 'Customer'">{{userName}}</a></li>
          <li><a href="vendorprofile"  v-if="userRole == 'Vendor'">{{userName}}</a></li>
          <li><a href="customerlogin"  v-if="userRole=='Guest'" style="color: white;">Login</a></li>
          <li><a href="vendorRegister" v-if="userRole=='Guest'" style="color: white;">Register</a></li>
          <li><a v-if="userRole !='Guest'" @click="logOut" style="color: white;">Logout</a></li>
          <li><a @click="closeNav" style="color: white;">Close</a></li>
          <div >
            <div class="">
              <form action="#" class="hm-searchbox" v-if="userRole != 'Vendor'">
                <input
                  type="text"
                  v-model="search"
                  @change="searchChange(search)"
                  placeholder="Enter your search key ..."
                />
                <router-link
                @click="closeNav"
                  class="header-search_btn"
                  :to="{ path: '/auction', query: { search: search } }"
                >
                  <i class="ion-ios-search-strong"><span>Search</span></i>
                </router-link>
              </form>
            </div>
          </div>
      </ul>
  </div>
</template>
<script>
import userService from "@/services/user.service";
export default {
   
 data() {
    return {
      userinfo: [],
      currentuserinfo: [],
      userRole: "Guest",
      userName: "",
      search: "",
      openNav: false
    };
  },
  watch: {
    changed_url(search) {
   
      this.search = search;
   
    },
  },
   computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      // console.log(this.returnAccount);
      return this.$store.state.auth.user;
    },
    returnAccount() {
       console.log("hi");
      return this.accountState(this.userRole);
    },
  },
  created(){
        this.accountState();
  },
  methods: {
    closeNav(event) {
      this.$emit("CloseNav", event.target.value);
    },
    searchChange(searchValue){
        if(searchValue == null || searchValue == ""){
        this.$route.query.search = null    
   
      }
        
    },
    accountState() {
      if (this.loggedIn) {
        this.userinfo = JSON.parse(localStorage.getItem("user"));
        if (typeof this.userinfo.customerId != "undefined") {
          this.getuserbyId(this.userinfo.customerId);
        } else if (typeof this.userinfo.sellerId != "undefined") {
          this.getsellerbyId(this.userinfo.sellerId);
        }
      } else {
        this.userRole = "Guest";
        this.userName = "My Account";
      }
    },
    getuserbyId(custId) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getcustomer(custId)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
            this.userRole = "Customer";
            this.userName = this.currentuserinfo.custName;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getsellerbyId(custId) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getsellerbyId(custId)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
            this.userRole = "Vendor";
            this.userName = this.currentuserinfo.sellerName;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    logOut() {
      this.userRole = "";     
      this.$store.dispatch("auth/logout");
      this.$router.push("/").catch(() => {
        "navbar";
      });
       this.$emit("CloseNav", event.target.value);
    },
  },
};
</script>
<style>
.sidenav {
    height: 100%;
    width: 250px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #10274a;
    overflow-x: hidden;
    padding: 20px;
}
.sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #ffffff;
    display: block;
}

.sidenav a:hover {
  color: #f1f1f1;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
</style>
