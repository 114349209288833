<template>
  <div>
    <!--  Banner Three Area start Here -->

    <!-- <section style="background-color: white; padding: 40px 15px;display:none;" >
    <div class="">
        <div class="" style="text-align: center; margin-bottom: 15px">
          <h3 style="color: black; padding-bottom: 10px">Reports</h3>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div>
              <div class="">
                <div class="reports-sec">
                  <div class="row">
                    <div class="col-md-6">
                      <div
                        style="background-color: #7057f6; border-radius: 5px"
                      >
                        <div
                          class="text-center"
                          style="padding: 20px 0px; margin-bottom: 20px"
                        >
                          <div>
                            <h6 style="color: white"></h6>
                          </div>
                          <div>
                            <h2 style="color: white">
                              <span><i class="fa fa-car"></i> </span>
                              {{
                                !home_Page_Summary[0]
                                  ? 0
                                  : home_Page_Summary[0].totalSellers
                              }}
                            </h2>
                          </div>
                          <div>
                            <p style="margin: 0px; color: white">
                              Total Sellers
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div
                        style="background-color: #eb6464; border-radius: 5px"
                      >
                        <div
                          class="text-center"
                          style="padding: 20px 0px; margin-bottom: 20px"
                        >
                          <div>
                            <h6 style="color: white"></h6>
                          </div>
                          <div>
                            <h2 style="color: white">
                              <span><i class="fa fa-car"></i> </span>
                              {{
                                !home_Page_Summary[0]
                                  ? 0
                                  : home_Page_Summary[0].totalCustomers
                              }}
                            </h2>
                          </div>
                          <div>
                            <p style="margin: 0px; color: white">
                              Total Customers
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div
                        style="background-color: #4fd6d2; border-radius: 5px"
                      >
                        <div
                          class="text-center"
                          style="padding: 20px 0px; margin-bottom: 20px"
                        >
                          <div>
                            <h6 style="color: white"></h6>
                          </div>
                          <div>
                            <h2 style="color: white">
                              <span><i class="fa fa-car"></i> </span>
                              {{
                                !home_Page_Summary[0]
                                  ? "Brand"
                                  : home_Page_Summary[0].mostSoldBrand
                              }}
                            </h2>
                          </div>
                          <div>
                            <p style="margin: 0px; color: white">
                              Most sold brand
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div
                        style="background-color: #65df6e; border-radius: 5px"
                      >
                        <div class="text-center" style="padding: 20px 0px">
                          <div>
                            <h6 style="color: white"></h6>
                          </div>
                          <div>
                            <h2 style="color: white">
                              <span><i class="fa fa-car"></i> </span>
                              {{
                                !home_Page_Summary[0]
                                  ? 0
                                  : home_Page_Summary[0].totalBids
                              }}
                            </h2>
                          </div>
                          <div>
                            <p style="margin: 0px; color: white">
                              Total Bids
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div>
              <img src="assets/images/graph.png" />
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="sec-main-paddi">
      <div class="container">
        <div class="new-recent-cars-title">
          <h3>RECENT <span>AUCTIONS</span></h3>
        </div>
        <div class="row">
          <div
            class="col-md-4"
            v-for="(recentAuction, index) in home_Page_Summary"
            :key="index"
          >
            <div class="recent-cars-new-wrap">
              <div>

               <router-link
                   @click="isCustomer ? null : gotoLogin()"
                    :to="{
                      name: isCustomer ? routerPath : 'home',
                      params: {
                        url_key: recentAuction.aucName,
                      },
                    }"
                    > <img
                  :src="imgPath + recentAuction.vehImage1"
                  class="img-fluid"
                /></router-link>
              </div>
              <div class="recent-cars-new-wrap-details">
                <div class="recent-cars-new-wrap-details-inner">
                 
                    <router-link
                    @click="isCustomer ? null : gotoLogin()"
                    :to="{
                      name:   isCustomer ? routerPath : 'home',
                      params: {
                        url_key: recentAuction.aucName,
                      },
                    }"
                    > <h4>
                    {{ recentAuction.brandName }} {{ recentAuction.modelName }}
                    {{ recentAuction.manYear }}    </h4></router-link
                  >
              

                  <router-link
                  class="bidNowBtn"
                    v-if="isCustomer"                  
                    :to="{
                      name: routerPath,
                      params: {
                        url_key: recentAuction.aucName,
                      },
                    }"
                    >Bid Now</router-link
                  >
                </div>
                <hr style="border-top: 1px solid #c1c1c14f" />
                <div class="recent-cars-new-wrap-details-bottom">
                  <div class="new-c-icon">
                    <i class="far fa-tachometer-alt"></i>
                    <span>{{ recentAuction.kmClocked }}</span>
                  </div>
                  <div class="new-c-icon">
                    <i class="far fa-dollar-sign"></i>
                    <span> {{ recentAuction.latestBidAmount }}</span>
                  </div>
                  <div class="new-c-icon">
                    <i class="fal fa-calendar-alt"></i>
                    <span>{{ recentAuction.manYear }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--  Banner Three Area End Here -->
  </div>
</template>
<script>
import Config from "../../config";
export default {
  props: {
    home_Page_Summary: Array,
  },
  data() {
    return {
      routerPath: "AuctionDetails",
    };
  },
  mounted() {},
  computed: {


  
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    isCustomer() {
      let userInfo = this.$store.state.auth.user;
      let is_Customer = false;

      if (userInfo && "customerId" in userInfo) {
        is_Customer = true;
      }

      return is_Customer;
    },
    imgPath() {
      return Config.BASE_URL_IMG;
    },
  },

  methods : {
    gotoLogin (){

      let userInfo = this.$store.state.auth.user;
      if (!userInfo) {
        this.$toast.warning("Please  sign in to  continue !", {
              position: "top",
              duration: 3000,
              queue: true
            });
      }

      
    }
  }
};
</script>

<style scoped></style>
