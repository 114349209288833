export default {

  
    BASE_URL: 'https://wpr.intertoons.net/canadabidapi/',
    // BASE_URL: 'https://localhost:44305/',
    BASE_URL_IMG: 'https://wpr.intertoons.net/canadabidadmin/',
    BASE_URLASSETS: 'https://wpr.intertoons.net/canadabidapi/',

   
    bidModalTimeout : 1000 ,
    itemPerPageAuction:12,
    pagelaoddelay:2,
    BASE_LANG:'en-US'
}