<template>
  <!-- Header bootstrap vue -->
  <nav  style="background-color: white; color: black;" :class="navClass" class="navbar navbar-expand-lg  custome-des-head">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img
          src="assets/images/menu/logo/2.png"
          alt="Logo"
          style="width: 165px"
        />
      </a>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarNav"
        style="justify-content: space-between"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link t-color-white" v-if="!currentUser" href="/">Home</a>
            <a class="nav-link t-color-white" v-else-if="userRole != 'Vendor'" href="/customerProfile">Home</a>
            <a class="nav-link t-color-white" v-else href="/vendorprofile">Home</a>
          </li>
          <li class="nav-item" v-if="userRole != 'Vendor'">
            <a class="nav-link t-color-white" href="/auction">Auction</a>
          </li>
        </ul>

        <ul class="navbar-nav">
          <li class="nav-item" v-if="userRole != 'Vendor'">
            <form class="">
              <div class="form-inline d-flex bs-head-search">
                <input
                  class="form-control mr-sm-2 custom-search-input"
                  type="search"
                  aria-label="Search"
                  v-model="search"
                  style="margin-right: 0px !important"
                  @keyup.enter.prevent="triggerAuctionSearch"
                  placeholder="Which vehicle are you looking for?..."
                />
                <!-- <button class="btn btn-outline-dark my-2 my-sm-0 ml-2" type="submit">Search</button> -->
                <button
                  style="margin-left: 0px !important"
                  class="btn my-2 my-sm-0 ml-2"
                  type="submit"
                  @click.prevent="triggerAuctionSearch"
                >
                  <i class="ion-ios-search-strong"></i><span> Search</span>
                </button>
              </div>
            </form>
          </li>
        </ul>

        <!-- @change="searchChange(search)" -->
        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle t-color-white"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              My Account
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li v-if="currentUser">
                <a class="dropdown-item" :href="myAccoutPath">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  {{ userName }}
                </a>
              </li>
              <li v-if="!currentUser">
                <a class="dropdown-item" href="/customerlogin">
                  <i class="fa fa-sign-in" aria-hidden="true"></i> Sign In
                </a>
              </li>
              <li v-if="!currentUser">
                <a class="dropdown-item" href="/customerRegister">
                  <i class="fa fa-user-plus" aria-hidden="true"></i> Sign Up
                </a>
              </li>
              <li v-if="currentUser">
                <a class="dropdown-item" @click="logOut">
                  <i class="fa fa-sign-out" aria-hidden="true"></i> Sign Out
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- Header bootstrap vue -->

  <div
    class="header-main_area header-main_area-2 header-main_area-3"
    style="display: none"
  >
    <div class="header-middle_area">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3 col-lg-2 col-md-3 col-sm-5 width50mobile">
            <div class="header-logo_area">
              <a href="/">
                <img
                  src="assets/images/menu/logo/2.png"
                  alt="Logo"
                  style="width: 165px"
                />
              </a>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 d-lg-block">
            <div class="for-big-screen hm-form_area">
              <form action="#" class="hm-searchbox" v-if="userRole != 'Vendor'">
                <input
                  type="text"
                  v-model="search"
                  @keyup.enter.prevent="triggerAuctionSearch"
                  placeholder="Which vehicle are you looking for?..."
                />
                <!-- @change="searchChange(search)" -->
                <button
                  class="header-search_btn"
                  type="submit"
                  @click.prevent="triggerAuctionSearch"
                >
                  <i class="ion-ios-search-strong"><span>Search</span></i>
                </button>
              </form>
            </div>
          </div>
          <div
            class="col-lg-5 col-md-9 col-sm-7 width50mobile"
            style="position: unset"
          >
            <div class="header-right_area" style="padding-top: 8px">
              <ul style="display: inline-block; text-align: right">
                <p class="breadcrumb-menu">
                  <span
                    style="font-size: 30px; cursor: pointer"
                    @click="openNav = !openNav"
                    >&#9776;
                  </span>
                </p>

                <div v-if="openNav">
                  <SideMenu @CloseNav="CloseSideMenu"></SideMenu>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-top_area bg--primary">
      <div class="container-fluid">
        <div class="row">
          <div class="custom-menu_col col-12 d-none d-lg-block">
            <div class="main-menu_area position-relative">
              <nav class="main-nav">
                <ul>
                  <li class="">
                    <a href="/" style="text-decoration: none">Home</a>
                  </li>

                  <li v-if="userRole != 'Vendor'" class="">
                    <a href="/auction" style="text-decoration: none"
                      >Auctions</a
                    >
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          <div class="custom-category_col col-12"></div>

          <div class="custom-setting_col col-12 d-none d-lg-block">
            <div class="ht-right_area">
              <div class="ht-menu">
                <ul>
                  <li v-if="userRole == 'Customer'">
                    <a class="overflow-h" href="#"
                      ><span class="fa fa-user"></span>
                      <span> Welcome {{ userName }}</span
                      ><i class="fa fa-chevron-down"></i
                    ></a>
                    <ul class="ht-dropdown ht-my_account">
                      <div v-if="!currentUser">
                        <li>
                          <a class="menu-hover-down" href="/customerRegister"
                            ><i class="fa fa-file-text" aria-hidden="true"></i>
                            Register</a
                          >
                        </li>
                        <li class="active">
                          <a class="menu-hover-down" href="/customerlogin"
                            ><i class="fas fa-sign-in"></i> Login</a
                          >
                        </li>
                      </div>
                      <div v-else>
                        <li v-if="userRole == 'Customer'">
                          <a class="menu-hover-down" href="/customerprofile"
                            >My Profile</a
                          >
                        </li>
                        <li>
                          <a class="menu-hover-down" @click="logOut"> Logout</a>
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li v-else-if="userRole == 'Vendor'">
                    <a href="#"
                      ><span class="fa fa-user"></span>
                      <span> Welcome {{ userName }}</span
                      ><i class="fa fa-chevron-down"></i
                    ></a>
                    <ul class="ht-dropdown ht-my_account">
                      <div v-if="!currentUser">
                        <li>
                          <a class="menu-hover-down" href="/vendorRegister"
                            ><i class="fa fa-file-text" aria-hidden="true"></i>
                            Register</a
                          >
                        </li>
                        <li class="active">
                          <a class="menu-hover-down" href="/vendorLogin"
                            ><i class="fas fa-sign-in"></i> Login</a
                          >
                        </li>
                      </div>
                      <div v-else>
                        <li v-if="userRole == 'Vendor'">
                          <a class="menu-hover-down" href="/vendorprofile"
                            >My Profile</a
                          >
                        </li>
                        <li>
                          <a class="menu-hover-down" @click="logOut"> Logout</a>
                        </li>
                      </div>
                    </ul>
                  </li>
                  <li v-else>
                    <a href="#"
                      ><span class="fa fa-user-circle"></span>
                      <span>My Account</span><i class="fa fa-chevron-down"></i
                    ></a>
                    <ul class="ht-dropdown ht-my_account">
                      <div v-if="!currentUser">
                        <li>
                          <a class="menu-hover-down" href="/customerRegister"
                            ><i class="fa fa-file" aria-hidden="true"></i>
                            Register</a
                          >
                        </li>
                        <li class="active">
                          <a class="menu-hover-down" href="/customerlogin"
                            ><i class="fas fa-sign-in"></i> Login</a
                          >
                        </li>
                      </div>
                      <div v-else>
                        <li>
                          <a class="menu-hover-down" @click="logOut"> Logout</a>
                        </li>
                      </div>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="for-tab custom-search_col col-12 d-none d-md-block d-lg-none"
          >
            <div class="hm-form_area">
              <form action="#" class="hm-searchbox">
                <select class="nice-select select-search-category">
                  <option value="0">Location</option>
                  <option value="10">Kochi</option>
                  <option value="17">Edappally</option>
                  <option value="20">Kaloor</option>
                  <option value="21">Kalamassery</option>
                </select>
                <input type="text" placeholder="Enter your search key ..." />
                <button class="header-search_btn" type="submit">
                  <i class="ion-ios-search-strong"><span>Search</span></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from "../components/modules/SideMenu.vue";
import userService from "../services/user.service";

export default {
  name: "Navbar",
  components: {
    SideMenu,
  },

  data() {
    return {
      userinfo: [],
      currentuserinfo: [],
      userRole: "Guest",
      userName: "",
      search: "",
      openNav: false,
      scrolledToTop: true,
    };
  },
  watch: {
    changed_url(search) {
      this.search = search;
    },
  },
  created() {
    if (this.loggedIn) {
      if (this.currentUser != null) {
        if (typeof this.currentUser.customerId != "undefined") {
          this.getuserbyId(this.currentUser.customerId);
        } else if (typeof this.currentUser.sellerId != "undefined") {
          this.getsellerbyId(this.currentUser.sellerId);
        }
      }
    } else {
      this.userRole = "Guest";
      this.userName = "My Account";
    }
  },

  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },

    myAccoutPath() {
      let path = "/";
      if (this.userRole == "Guest") {
        path = "/";
      } else if (this.userRole == "Customer") {
        path = "/customerprofile";
      } else if (this.userRole == "Vendor") {
        path = "/vendorprofile";
      }
      return path;
    },
    navClass() {
      return {
        'scroll-nav-top': this.scrolledToTop, // Apply class if scrolledToTop is true
        'navbar-light': !this.scrolledToTop, // Change navbar color to white when scrolled down
        'navbar-dark': this.scrolledToTop, // Change navbar color to dark when scrolled to top
      };
    },  
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  methods: {
    handleScroll() {
      // Update the scrolledToTop based on scroll position
      this.scrolledToTop = window.scrollY === 0;
    },
    triggerAuctionSearch() {
      // if(this.search){
      //   this.$route.query.search = null;
      // }
      this.$router.push({ path: "/auction", query: { search: this.search } });
    },
    CloseSideMenu() {
      this.openNav = false;
    },
    // searchChange(searchValue) {

    //   if (searchValue == null || searchValue == "") {
    //     this.$route.query.search = null;
    //   }
    // },

    getuserbyId(custId) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getcustomer(custId)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
            this.userRole = "Customer";
            this.userName = this.currentuserinfo.custName;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getsellerbyId(custId) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getsellerbyId(custId)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
            this.userRole = "Vendor";
            this.userName = this.currentuserinfo.sellerName;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    logOut() {
      this.userRole = "";
      this.$store.dispatch("auth/logout");
      this.$router.push("/").catch(() => {
        "navbar";
      });
    },
  },
};
</script>
