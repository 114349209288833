<template>
  <navbar></navbar>
  <div class="">
    <MainSlider
      v-if="bannerlist.length !== 0"
      :mainBanner="bannerlist.result1"
    ></MainSlider>
    <div class="">
      <TopBanner :home_Page_Summary="homePageSummary"></TopBanner>
      <BannerCard
        v-if="bannserImages"
        :bannserImages="bannserImages"
        :bannerStyle="'col-lg-4 col-md-6'"
      ></BannerCard>
    </div>
    <div v-if="hotAuctions.length >= 4">
      <AuctionSlider
        :VehicleDetails="hotAuctions"
        :AuctionName="'Popular Auctions'"
        @wishList="wishListedhot"
      ></AuctionSlider>
    </div>

    <div class="">
      <MidBanner :home_Page_Summary="todaysAuctions"></MidBanner>

      <BottomBanner
        v-if="bannerlist.length !== 0"
        :bottom-banner-l-ist="bannerlist.result3"
      ></BottomBanner>
    </div>

    <!-- <div v-if="todaysAuctions.length >= 4">
      <VehicleCardMin
        :VehicleDetails="todaysAuctions"
        :AuctionName="'Latest Vehicles'"
        @wishList="wishListedtoday"
      ></VehicleCardMin>
    </div> -->

    <!-- NEW VEH TILES STARTS HERE -->
    <!--  need add brand list now static -->
    <!-- <div  v-if="brands"  >
      <TypeBrands :TypeBrands="brands" ></TypeBrands>

    </div> -->
  </div>
  <FooterTag></FooterTag>
</template>

<script>
// components
import navbar from "@/components/navbar.vue";
import MainSlider from "../components/modules/MainSlider.vue";
import TopBanner from "../components/modules/TopBanner.vue";
import MidBanner from "../components/modules/MidBanner.vue";
import BannerCard from "../components/modules/BannerCard.vue";
import AuctionSlider from "../components/modules/AuctionSlider.vue";
import BottomBanner from "../components/modules/BottomBanner.vue";
//import TypeBrands from "../components/modules/TypeBrands.vue";
//import VehicleCardMin from "../components/modules/VehicleCardMin.vue";
import FooterTag from "@/components/FooterTag.vue";
// service
import userService from "../services/user.service";
export default {
  name: "Home",
  data() {
    return {
      images: [],
      bannserImages: [],
      MainBannser: [],

      brands: [
        { title: "assets/images/brand/1.png", id: 1 },
        { title: "assets/images/brand/2.png", id: 2 },
        { title: "assets/images/brand/3.png", id: 3 },
        { title: "assets/images/brand/4.png", id: 4 },
        { title: "assets/images/brand/5.png", id: 5 },
        { title: "assets/images/brand/6.png", id: 6 },
        { title: "assets/images/brand/7.png", id: 7 },
        { title: "assets/images/brand/8.png", id: 8 },
        { title: "assets/images/brand/9.png", id: 9 },
        { title: "assets/images/brand/10.png", id: 10 },
        { title: "assets/images/brand/11.png", id: 11 },
        { title: "assets/images/brand/12.png", id: 12 },
      ],
      bannerlist: [],
      todaysAuctions: [],
      hotAuctions: [],
      userinfo: "",
      custId: "",
      homePageSummary: [],
    };
  },
  components: {
    MainSlider,
    TopBanner,
    MidBanner,
    BannerCard,
    AuctionSlider,
    //TypeBrands,
    //VehicleCardMin,
    FooterTag,
    BottomBanner,
    navbar,
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    window.scrollTo(0, 0);

    
  },

  created() {
    // if(this.loggedIn){
    //   this.userinfo = this.$store.state.auth.user;
    //   if (this.userinfo != null) {
    //     if (typeof this.userinfo.customerId != "undefined") {
    //       this.custId = this.userinfo.customerId;
    //     } else {
    //       console.log("vendor");
    //     }
    //   }
    // }

    this.todaysAuction();
    //this.hotauction();
    this.getBanners();
    this.getHomePageSummary();
  },
  methods: {
    wishListedhot() {
      this.hotauction();
    },
    // wishListedtoday() {
    //   this.todaysAuction();
    // },
    getBanners() {
      userService
        .getBanners()
        .then((response) => {
          this.bannerlist = response.data.Data;
          //console.log(this.bannerlist);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    todaysAuction() {
      userService
        .todaysAuction(this.custId)
        .then((response) => {
          this.todaysAuctions = response.data.Data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    hotauction() {
      userService
        .hotauction(this.custId)
        .then((response) => {
          this.hotAuctions = response.data.Data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getHomePageSummary() {
      userService
        .getHomePageSummary()
        .then((response) => {
          this.homePageSummary = response.data.Data;
          //console.log( this.homePageSummary );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style >

</style>