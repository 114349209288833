import axios from "axios";
import authHeader from "./auth-header";
import config from "../config";
const API_URL = config.BASE_URL;

class UserService {
  forgotpassword(data) {
    let payload = {
      sp: "forgotPassword",
      username: data,
    };
    // console.log(payload);
    return axios.post(API_URL, payload);
  }
  forgotpasswordotp(data, items) {
    let payload = {
      sp: "resetPassword",
      custId: items.custId,
      otp: data.otp,
      password: data.password,
    };
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }

  changepasswordcustomer(custId, data) {
    let payload = {
      sp: "changePassword",
      custId: custId,
      currentPassword: data.currentpassword,
      newPassword: data.newpassword,
    };
    // console.log(payload);
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }

  // Banner
  getBanners() {
    return axios.get(API_URL + "?sp=getBanners");
  }
  // auctions slider
  todaysAuction(id) {
    return axios.get(API_URL + "?sp=getTodaysAuctions&custId=" + id);
  }
  hotauction(id) {
    return axios.get(API_URL + "?sp=getHotAuctions&custId=" + id);
  }
  getHomePageSummary() {
    return axios.get(API_URL + "?sp=getHomePageSummary");
  }


  //auction details
  getCustomerauctionbyname(id) {
    return axios.get(API_URL + "?sp=getAuctionDetailsbyId&aucName=" + id);
  }

  getcustomer(id) {

    // console.log(id, authHeader());
    return axios.get(API_URL + "?sp=getCustomerProfileById&custId=" + id, {
      headers: authHeader(),
    });
  }

  getDashboardProfileFe(id, type) {
    return axios.get(
      API_URL + "?sp=getDashboardProfileFe&Id=" + id + "&type=" + type
    );
  }

  updateCustomerProfile(id, data) {
    let payload = {
      sp: "updCustomerProfile",
      fullName: data.Name,
      email: data.email,
      phone: data.phone,
      custId: id,
    };

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  getvehbyId(id) {
    return axios.get(API_URL + "?sp=getAuctionsList&custId=" + id);
  }
  changePasswordVendor(custId, data) {
    let payload = {
      sp: "vendorchangePassword",
      sellerId: custId,
      currentPassword: data.currentpassword,
      newPassword: data.newpassword,
    };
    // console.log(payload);
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }

  addbid(item, custId, bidamount) {
    // console.log(item.LatestBidAmount);

    let bidamt = bidamount;

    let payload = {
      sp: "spBid",
      custId: custId,
      aucId: item.aucId,
      bidAmount: bidamt,
    };
    // console.log(payload);

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }

  getbid(id) {
    return axios.get(API_URL + "?sp=getCustomerBids&custId=" + id, {
      headers: authHeader(),
    });
  }
  getCustomerWishlists(id) {
    return axios.get(API_URL + "?sp=getCustomerWishlists&custId=" + id, {
      headers: authHeader(),
    });
  }
  getCustomerWinnings(id) {
    return axios.get(API_URL + "?sp=getCustomerWinnings&custId=" + id, {
      headers: authHeader(),
    });
  }
  addToWhishlist(id, data) {
    let payload = {
      sp: "insCustomerWishlists",
      custId: id,
      vehId: data.vehId,
    };

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }

  rmvfromWhishlist(data, cusid) {
    let payload = {
      sp: "delCustomerWishlists",
      custId: cusid,
      vehId: data.vehId,
    };
    // console.log();

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }




  vehcleDelet(sellerId, vehcleId) {
    let payload = {
      sp: "delVehicleBySeller",
      vehId: vehcleId,
      sellerId: sellerId,
    };
    console.log(payload);

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }

  getProcessWinningsCustomer(id) {
    return axios.get(
      API_URL + "?sp=getProcessWinningsAgainstCustomer&custId=" + id,
      {
        headers: authHeader(),
      }
    );
  }

  getCustomerAuctionsStatus(id, auctionStatus) {
    return axios.get(
      API_URL +
      "?sp=getCustomerAuctionsStatus&custId=" +
      id +
      "&auctionStatus=" +
      auctionStatus,
      {
        headers: authHeader(),
      }
    );
  }
  getCustomerClosingAuctions(id, auctionStatus) {
    return axios.get(
      API_URL +
      "?sp=getCustomerClosingAuctions&custId=" +
      id +
      "&auctionStatus=" +
      auctionStatus,
      {
        headers: authHeader(),
      }
    );
  }
  getCustomerWonList(id, auctionStatus) {
    return axios.get(
      API_URL +
      "?sp=getCustomerWonList&custId=" +
      id +
      "&auctionStatus=" +
      auctionStatus,
      {
        headers: authHeader(),
      }
    );
  }
  //////////////////// seller

  getsellerbyId(id) {
    return axios.get(API_URL + "?sp=getSellerProfileById&sellerId=" + id, {
      headers: authHeader(),
    });
  }

  updateSellerProfile(id, data) {
    let payload = {
      sp: "updSellerProfile",
      fullName: data.Name,
      email: data.email,
      phone: data.phone,
      address: data.address,
      sellerId: id,
    };

    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }

  getUpcomingAuctions(id) {
    return axios.get(API_URL + "?sp=getUpcomingAuctionsBySeller&sellerId=" + id);
  }
  

  getVehiclesListBySellerId(id) {
    return axios.get(API_URL + "?sp=getVehiclesListBySellerId&sellerId=" + id, {
      headers: authHeader(),
    });
  }
  getSellerLostAuctionsList(id) {
    return axios.get(API_URL + "?sp=getSellerLostAuctionsList&sellerId=" + id, {
      headers: authHeader(),
    });
  }
  // getpendingSellerAuctions(id) {
  //   return axios.get(API_URL + '?sp=getpendingSellerAuctions&sellerId=' + id, {
  //     headers: authHeader()
  //   });

  // }
  getSellerAuctionsStatus(id, auctionStatus) {
    return axios.get(
      API_URL +
      "?sp=getSellerAuctionsStatus&sellerId=" +
      id +
      "&auctionStatus=" +
      auctionStatus,
      {
        headers: authHeader(),
      }
    );
  }

  getSellerAuctionsStatusForSecondCustomer(sellerId, auctionStatus, custId, aucId) {
    return axios.get(
      API_URL +
      "?sp=getSellerAuctionsStatusForSecondCustomer&sellerId=" +
      sellerId +
      "&auctionStatus=" +
      auctionStatus + "&custId=" + custId + "&aucId=" + aucId,
      {
        headers: authHeader(),
      }
    );
  }
  getSellerParkedAuctions(id, auctionStatus) {
    return axios.get(
      API_URL +
      "?sp=getSellerParkedAuctions&sellerId=" +
      id +
      "&auctionStatus=" +
      auctionStatus,
      {
        headers: authHeader(),
      }
    );
  }
  getSellerLostAuctions(id, auctionStatus) {
    return axios.get(
      API_URL +
      "?sp=getSellerLostAuctions&sellerId=" +
      id +
      "&auctionStatus=" +
      auctionStatus,
      {
        headers: authHeader(),
      }
    );
  }

  getNegotiationTimeLine(userType, e) {

    console.log(e);
    return axios.get(
      API_URL +
      "?sp=getNegotiationTimeLine&aucId=" +
      e.aucId +
      "&userType=" +
      userType + "&isFirstCustomer=" + e.isFirstCustomer + "&custId=" + e.ProcessCustomerId
    );
  }
  getVehicleBrand() {
    return axios.get(API_URL + "?sp=getBrand");
  }
  getVehicleModel(make) {
    return axios.get(API_URL + "?sp=getModel&brandName=" + make);
  }
  getVehicleVariant(model) {
    return axios.get(API_URL + "?sp=getVariant&modelName=" + model);
  }
  getVehicleTypes() {
    return axios.get(API_URL + "?sp=getVehicleTypes");
  }
  getStatebyLocId() {
    return axios.get(API_URL + "?sp=getStatebyLocId");
  }
  getDistrictbyLocId(id) {
    return axios.get(API_URL + "?sp=getDistrictbyLocId&state=" + id);
  }
  getLocationIdbyLocName(id) {
    return axios.get(API_URL + "?sp=getLocIdbylocName&locName=" + id);
  }
  getLocationByStateId(id) {
    return axios.get(API_URL + "?sp=getlocNamebyLocId&district=" + id);
  }
  getVINDetails(VIN, sellerId) {
    return axios.get(API_URL + "?sp=getVINDetails&VIN=" + VIN + "&sellerId=" + sellerId);
  }

  getAuctionTimeSlot() {
    return axios.get(API_URL + "?sp=getAuctionTimeSlot&isAdmin= 0");
  }
  getVehicleResearchList(value) {
    console.log(value);
    let payload = {
      sp: "getVehicleResearchList",
      Make: value.Make,
      Model: value.Model,
      Trim: value.Trim,
      mileageMin: value.mileageMin,
      mileageMax: value.mileageMax,
      yearOfMakeMax: value.yearOfMakeMax,
      yearOfMakeMin: value.yearOfMakeMin,
      VIN: value.VIN,
      sortBy: value.sortBy,
    };
    console.log(payload);
    return axios.post(API_URL, payload);
  }

  getAvgPrice(brand, model, km, yom) {
    //console.log(brand, model, km, yom);

    // let payload ={
    //   sp:"getAugVehiclePrice",
    //   brandName:brand,
    //   modelName:model,
    //   kmClocked:km,
    //   manYear:yom,
    //   // variant:"",
    // }
    return axios.get(
      API_URL +
      "?sp=getAvgVehiclePrice&brandName=" +
      brand +
      "&modelName=" +
      model +
      "&kmClocked=" +
      km +
      "&manYear=" +
      yom
    );
  }
  updNegHistory(data, aucStatus, winStatus, userId) {
    let custid = "";
    let SellerId = "";
    let AucId = "";

    if (userId == "Customer") {
      custid = data.custId;
      AucId = data.aucId;
    } else {
      SellerId = data.sellerId;
      AucId = data.aucid;
    }

    let payload = {
      sp: "updNegHistory",
      aucId: AucId,
      auctionStatus: aucStatus,
      custId: custid,
      sellerId: SellerId,
      aucapproveStatus: winStatus,
    };
    console.log(payload);
    return axios.post(API_URL, payload);
  }
  extentAuction(data, userid) {
    let payload = {
      sp: "extentAuction",
      aucId: data.aucId,
      extAuchours: data.hours,
      extAucmin: data.min,
      vehid: data.vehId,
      userId: userid,
    };
    // console.log(payload);
    return axios.post(API_URL, payload);
  }
  updAuctionStatus(data, aucStatus) {
    let AucId = "";
    if (data.aucId) {
      AucId = data.aucId;
    } else if (data.aucid) {
      AucId = data.aucid;
    }

    let payload = {
      sp: "updAuctionStatus",
      aucId: AucId,
      auctionStatus: aucStatus,
    };
    console.log(payload);
    return axios.post(API_URL, payload);
  }
  updkmclocked(data, km) {
    console.log(data, km);
    let payload = {
      sp: "updVehicleKmclcoked",
      vehId: data.vehId,
      sellerId: data.sellerId,
      kmClocked: km,
    };
    console.log(payload);
    return axios.post(API_URL, payload);
  }
  createAuction(data, userid, slotId) {
    //console.log("f-data : " ,data);
    let payload = {
      sp: "spCreateAuction",
      vehid: data.vehId,
      aucId: data.aucid,
      aucDate: "",
      aucStart: 0,
      aucMin: 0,
      aucMat: "",
      aucMinWin: data.MinWinAmount,
      aucMaxExtDate: "",
      compercent: 0,
      autoBid: 0,
      userId: userid,
      userMode: "Seller",
      ausStatus: "Reauctioned",
      auctionSlotId: slotId,

    };
    console.log("payload : ", payload);
    return axios.post(API_URL, payload);
  }
  insNegotiationDetails(negotiationDetail, offerAmount, userId) {
    // console.log(negotiationDetail);
    let custid = "";
    let SellerId = "";
    let AucId = "";
    if (userId == "Customer") {
      custid = negotiationDetail.custId;
      AucId = negotiationDetail.aucId;
    } else {
      SellerId = negotiationDetail.sellerId;
      AucId = negotiationDetail.aucid;
    }
    let payload = {
      sp: "insNegotiation",
      aucId: AucId,
      custId: custid,
      sellerId: SellerId,
      negotiationAmount: offerAmount,
      negotiationStatus: true,
    };
    // console.log(payload);
    return axios.post(API_URL, payload);
  }
  getVehiclesDetailsvehId(sellerId, vehId) {
    return axios.get(
      API_URL +
      "?sp=getVehiclesDetailsvehId&sellerId=" +
      sellerId +
      "&vehId=" +
      vehId,
      {
        headers: authHeader(),
      }
    );
  }

  insVINDetails(data) {

    console.log(data);
    return axios.post(API_URL, data);
  }

  addVeh(id, veh, image) {
    // console.log(veh.$model);

    let image1 = "";
    let image2 = "";
    let image3 = "";
    let image4 = "";
    let image5 = "";
    let image6 = "";
    let image7 = "";
    let image8 = "";
    let image9 = "";
    let image10 = "";
    let image11 = "";
    let image12 = "";
    let image13 = "";
    let image14 = "";
    let image15 = "";

    if (image.length > 0) {
      for (let index = 0; index < image.length; index++) {
        image1 = image[0];
        if (typeof image[1] != "undefined") {
          image2 = image[1];
        } else {
          image2 = "";
        }
        if (typeof image[2] != "undefined") {
          image3 = image[2];
        } else {
          image3 = "";
        }
        if (typeof image[3] != "undefined") {
          image4 = image[3];
        } else {
          image4 = "";
        }
        if (typeof image[4] != "undefined") {
          image5 = image[4];
        } else {
          image5 = "";
        }
        if (typeof image[5] != "undefined") {
          image6 = image[5];
        } else {
          image6 = "";
        }
        if (typeof image[6] != "undefined") {
          image7 = image[6];
        } else {
          image7 = "";
        }
        if (typeof image[7] != "undefined") {
          image8 = image[7];
        } else {
          image8 = "";
        }
        if (typeof image[8] != "undefined") {
          image9 = image[8];
        } else {
          image9 = "";
        }
        if (typeof image[9] != "undefined") {
          image10 = image[9];
        } else {
          image10 = "";
        }
        if (typeof image[10] != "undefined") {
          image11 = image[10];
        } else {
          image11 = "";
        }
        if (typeof image[11] != "undefined") {
          image12 = image[11];
        } else {
          image12 = "";
        }
        if (typeof image[12] != "undefined") {
          image13 = image[12];
        } else {
          image13 = "";
        }
        if (typeof image[13] != "undefined") {
          image14 = image[13];
        } else {
          image14 = "";
        }
        if (typeof image[14] != "undefined") {
          image15 = image[14];
        } else {
          image15 = "";
        }
      }
    }

    let payload = {
      sp: "insVehicle",
      sellerId: id,
      vehRegNo: veh.$model.vinNumber,
      vehPrice: veh.$model.vehPrice,
      vehMinPrice: 0,
      vehdtls: veh.$model.description,
      locId: veh.$model.selectedCity,
      kmClocked: veh.$model.kmClocked,
      manYear: veh.$model.yearofmake,
      underWarantee: parseInt(veh.$model.underWarantee),
      vehTypeId: veh.$model.veType,
      brandName: veh.$model.vehmake,
      modelName: veh.$model.vehmodel,
      variant: veh.$model.vehVariant,
      abs: 1,
      accidental: 1,
      adjustableSteering: 1,
      alloyWheels: 1,
      antiTheftDevice: 1,
      auxCompatibility: 1,
      Color: "red",
      insuranceType: "Third party",
      bluetooth: 1,
      vehicleCertified: 1,
      navigationSystem: 1,
      parkingSensors: 1,
      powerSteering: 1,
      FMRadio: 1,
      rearParkingCamera: 1,
      exchange: 1,
      finance: 1,
      serviceHistory: 1,
      usb: 1,
      HP: 80,
      sunroof: 1,
      vehImage1: image1,
      vehImage2: image2,
      vehImage3: image3,
      vehImage4: image4,
      vehImage5: image5,
      vehImage6: image6,
      vehImage7: image7,
      vehImage8: image8,
      vehImage9: image9,
      vehImage10: image10,
      vehImage11: image11,
      vehImage12: image12,
      vehImage13: image13,
      vehImage14: image14,
      vehImage15: image15,
    };

    // let payload = {
    //   "sp": "insVehicle",
    //   "sellerId": id,
    //   "vehRegNo": veh.vinNumber,
    //   "vehPrice": veh.vehPrice,
    //   "vehMinPrice": 0,
    //   "vehdescription": veh.description,
    //   "locId": veh.city,
    //   "kmClocked": veh.kmClocked,
    //   "manYear": veh.yearofmake,
    //   "underWarantee": this.warranty_,
    //   "vehImage1": image1,
    //   "vehImage2": image2,
    //   "vehImage3": image3,
    //   "vehImage4": image4,
    //   "vehImage5": image5,
    //   "vehImage6": image6,
    //   "vehImage7": image7,
    //   "vehImage8": image8,
    //   "vehImage9": image9,
    //   "vehImage10": image10,
    //   "vehImage11": image11,
    //   "vehImage12": image12,
    //   "vehImage13": image13,
    //   "vehImage14": image14,
    //   "vehImage15": image15,
    //   "vehTypeId": veh.vehType,
    //   "brandName": veh.vehBrand,
    //   "modelName": veh.vehModel,

    // }

    console.log(payload);
    return axios.post(API_URL, payload);
  }

  randomVehadd(data) {
    // console.log(data);
    return axios.post(API_URL, data);
  }

  getProcessWinnersAgainstSellerAuctions(id) {
    return axios.get(
      API_URL + "?sp=getProcessWinnersAgainstSellerAuctions&sellerId=" + id,
      {
        headers: authHeader(),
      }
    );
  }
  getWonCustomersAgainstSellerAuctions(id) {
    return axios.get(
      API_URL + "?sp=getWonCustomersAgainstSellerAuctions&sellerId=" + id,
      {
        headers: authHeader(),
      }
    );
  }
  getAuctionListBySellerId(id) {
    return axios.get(API_URL + "?sp=getSellerAuctionsList&sellerId=" + id, {
      headers: authHeader(),
    });
  }

  getLiveAuctionBySellerId(id) {
    return axios.get(API_URL + "?sp=getSellerLiveAuctionsList&sellerId=" + id, {
      headers: authHeader(),
    });
  }
  getAuctionLogByAucId(id) {
    return axios.get(API_URL + "?sp=getAuctionLogByAucId&aucId=" + id);
  }

  getReAuctionLog(id) {
    return axios.get(API_URL + "?sp=getReAuctionLog&aucId=" + id);
  }

  getSellerAuctionsListbysellerIdVechId(vehId, sellerId) {

    return axios.get(
      API_URL +
      "?sp=getSellerAuctionsListbysellerIdVechId&sellerId=" +
      sellerId +
      "&vehId=" +
      vehId,
      {
        headers: authHeader(),
      }
    );
  }
  vendorforgotpassword(data) {
    let payload = {
      sp: "vendorforgotpassword",
      username: data,
    };
    // console.log(payload);
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  vendorforgotpasswordotp(data, item) {
    console.log();
    let payload = {
      sp: "vendorresetPassword",
      sellerId: item.sellerId,
      otp: data.otp,
      password: data.confirmPassword,
    };
    return axios.post(API_URL, payload, {
      headers: authHeader(),
    });
  }
  updateVechDetails(paylod, image) {
    let image1 = "";
    let image2 = "";
    let image3 = "";
    let image4 = "";
    let image5 = "";
    let image6 = "";
    let image7 = "";
    let image8 = "";
    let image9 = "";
    let image10 = "";
    let image11 = "";
    let image12 = "";
    let image13 = "";
    let image14 = "";
    let image15 = "";
    if (image.length > 0) {
      for (let index = 0; index < image.length; index++) {
        if (image[0] != null) {
          image1 = image[0];
        } else {
          image1 = "";
        }
        if (image[1] != null) {
          image2 = image[1];
        } else {
          image2 = "";
        }
        if (image[2] != null) {
          image3 = image[2];
        } else {
          image3 = "";
        }
        if (image[3] != null) {
          image4 = image[3];
        } else {
          image4 = "";
        }
        if (image[4] != null) {
          image5 = image[4];
        } else {
          image5 = "";
        }
        if (image[5] != null) {
          image6 = image[5];
        } else {
          image6 = "";
        }
        if (image[6] != null) {
          image7 = image[6];
        } else {
          image7 = "";
        }
        if (image[7] != null) {
          image8 = image[7];
        } else {
          image8 = "";
        }
        if (image[8] != null) {
          image9 = image[8];
        } else {
          image9 = "";
        }
        if (image[9] != null) {
          image10 = image[9];
        } else {
          image10 = "";
        }
        if (image[10] != null) {
          image11 = image[10];
        } else {
          image11 = "";
        }
        if (image[11] != null) {
          image12 = image[11];
        } else {
          image12 = "";
        }
        if (image[12] != null) {
          image13 = image[12];
        } else {
          image13 = "";
        }
        if (image[13] != null) {
          image14 = image[13];
        } else {
          image14 = "";
        }
        if (image[14] != null) {
          image15 = image[14];
        } else {
          image15 = "";
        }
      }
    }
    if (paylod.warrenty == "Yes") {
      this.warranty_ = 1;
    } else {
      this.warranty_ = 0;
    }

    let payload = {
      sp: "updVehicle",
      vehId: paylod.vehId,
      sellerId: paylod.sellerId,
      vehRegNo: paylod.vinNumber,
      vehPrice: paylod.vehPrice,
      vehMinPrice: 0,
      vehdescription: paylod.description,
      locId: paylod.city,
      kmClocked: paylod.kmClocked,
      manYear: paylod.yearofmake,
      brandName: paylod.vehBrand,
      modelName: paylod.vehModel,
      vehTypeId: paylod.vehType,
      underWarantee: this.warranty_,
      vehImage1: image1,
      vehImage2: image2,
      vehImage3: image3,
      vehImage4: image4,
      vehImage5: image5,
      vehImage6: image6,
      vehImage7: image7,
      vehImage8: image8,
      vehImage9: image9,
      vehImage10: image10,
      vehImage11: image11,
      vehImage12: image12,
      vehImage13: image13,
      vehImage14: image14,
      vehImage15: image15,
    };

    console.log(payload);

    return axios.post(API_URL, payload);
  }
  getAuctionListSearch(customerId, searchkey) {
    let payload = {
      sp: "searchVehicles",
      searchkey: searchkey,
      custId: customerId,
    };
    return axios.post(API_URL, payload);
  }
  //////////////////////////////////////////
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios.get(API_URL + "user", {
      headers: authHeader(),
    });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", {
      headers: authHeader(),
    });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", {
      headers: authHeader(),
    });
  }

  getUpcomingAuctionsData() {
    return axios.get(API_URL + "?sp=getUpcomingAuctions&custId=60");
  }


}

export default new UserService();
