<template>
  <!-- <div class="container"> -->
  <!-- <div class="row"> -->
  <!-- <div class="col-md-4"> -->
  <div>
    <div class="card card-container loginFormwrap">
      <div>
        <div>
          <h4>Seller Login</h4>
          <p>Please login to continue</p>
        </div>
      </div>
      <Form @submit="handleLogin" :validation-schema="schema">
        <div class="form-group">
          <label for="username">Username (Email ID)</label>
          <Field
            name="username"
            type="text"
            class="form-control"
            placeholder="Enter your username"
          />
          <ErrorMessage name="username" class="error-feedback" />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <div style="position: relative">
            <Field
              name="password"
              :type="passwordCls"
              class="form-control"
              placeholder="Enter your password"
            />
            <button
              type="button"
              id="btnToggle"
              class="toggle passeye"
              @click="changeType()"
            >
              <i
                id="eyeIcon"
                v-if="passwordCls == 'text'"
                class="fas fa-eye-slash"
              ></i>
              <i
                id="eyeIcon"
                v-if="passwordCls == 'password'"
                class="fa fa-eye"
              ></i>
            </button>
          </div>
          <ErrorMessage name="password" class="error-feedback" />
        </div>

        <div class="form-group">
          <button
            style="background-color: #bf0a30; color: white"
            class="btn btn-block"
            :disabled="loading"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>LOGIN</span>
          </button>
        </div>
        <div>
          <div class="row">
            <div class="col-12 text-center">
              <router-link
                to="vendorForgotPassword"
                style="color: black; text-decoration: none"
                ><span class="forgot-txt">Forgot Password?</span>
              </router-link>
              <h6 class="signup-text">
                Don't have an account?
                <router-link
                  to="customerRegister"
                  style="color: blue; text-decoration: none"
                >
                  Sign Up
                </router-link>
              </h6>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
  <!-- </div> -->
  <!-- <section class="loginpagebackground">
    
  </section> -->
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
// import localstorageAuth from "@/components/models/localstorageAuth.js";

export default {
  name: "Vendor Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!")  .min(6, "Password must be at least 6 characters"),
    });

    return {
      loading: false,
      message: "",
      schema,
      passwordCls: "password",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      let userinfo = JSON.parse(localStorage.getItem("user"));
      if (
        typeof userinfo.sellerId == "undefined" ||
        userinfo.sellerId == "" ||
        userinfo.sellerId == null
      ) {
        this.$router.go(-1);
      }
    }
  },
  methods: {
    changeType() {
      this.passwordCls = this.passwordCls == "password" ? "text" : "password";
    },
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/vendorlogin", user).then(
        () => {
          this.$router.push("/vendorProfile");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.Message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
