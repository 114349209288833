<template>
  <div id="app" v-cloak>
    
    <router-view />
    <!-- <FooterTag></FooterTag> -->
  </div>
</template>
<script>
// import navbar from "@/components/navbar.vue";
// import FooterTag from "@/components/FooterTag.vue";
export default {
  components: {
    // navbar,
    // FooterTag,
  }, 
  
};


</script>
