<template>
  <navbar></navbar>
  <div style="text-align: center;">
   <img src='https://i.imgur.com/1ED9uAD.png'>  
  </div>
  <FooterTag></FooterTag>
</template>

<script>
import FooterTag from "@/components/FooterTag.vue";
 import navbar from "@/components/navbar.vue";    
export default {
  name: "comingsoon",
  components : {
    navbar,
    FooterTag
  },


  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {},
};
</script>
