<template>
   <!-- Begin Footer Area -->
      <div class="uren-footer_area">
        <div class="footer-top_area">
          <div class="container">
            <div class="row">
              <div class="col-md-6">

              </div>
              <div class="col-md-6">
                <div>
                  <div class="newsletter-area">
                    <h3 class="title">Join Our Newsletter Now</h3>
                    <p class="short-desc">
                      Get E-mail updates about our latest shop and special offers.
                    </p>
                    <div class="newsletter-form_wrap">
                      <form
                        action=""
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        class="newsletters-form validate"
                        target="_blank"
                        novalidate
                      >
                        <div id="mc_embed_signup_scroll">
                          <div id="mc-form" class="mc-form subscribe-form">
                            <input
                              id="mc-email"
                              class="newsletter-input"
                              type="email"
                              autocomplete="off"
                              placeholder="Enter your email"
                            />
                            <button  disabled class="newsletter-btn" id="mc-submit">
                              Subscribe
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-middle_area">
          <div class="container">
            <div class="row">
              <div class="col-lg-3">
                <div class="footer-widgets_info">
                  <div class="footer-widgets_logo">
                    <a href="#">
                      <img
                        src="assets/images/logo/logoBlack.png"
                        alt="Uren's Footer Logo"
                      />
                    </a>
                  </div>
                  <div class="widget-short_desc">
                    <p>
                      We provide the easiest and most straightforward way to buy and sell used equipment and vehicles
                    </p>
                  </div>
                
                  <div class="uren-social_link">
                    <ul>
                      <li class="facebook">
                        <a
                          href="https://www.facebook.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Facebook"
                        >
                          <i class="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li class="twitter">
                        <a
                          href="https://twitter.com/"
                          data-toggle="tooltip"
                          target="_blank"
                          title="Twitter"
                        >
                          <i class="fab fa-twitter-square"></i>
                        </a>
                      </li>
                      
                      <li class="instagram">
                        <a
                          href=""
                          data-toggle="tooltip"
                          target="_blank"
                          title="Instagram"
                        >
                          <i class="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-9">
                <div class="footer-widgets_area">
                  <div class="row">
                    <div class="col-lg-3 col-md-6 col-6">
                      <div class="footer-widgets_title">
                        <h3>Information</h3>
                      </div>
                      <div class="footer-widgets">
                        <ul>
                          <li><a href="/comingsoon">About Us</a></li>
                          <li>
                            <a href="/comingsoon"
                              >Delivery Information</a
                            >
                          </li>
                          <li>
                            <a href="/comingsoon">Privacy Policy</a>
                          </li>
                          <li>
                            <a href="/comingsoon">Terms & Conditions</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-6">
                      <div class="footer-widgets_title">
                        <h3>Quick Links</h3>
                      </div>
                      <div class="footer-widgets">
                        <ul>
                          <li><a href="/">Home</a></li>
                          <li>
                            <a href="/auction"
                              >Auctions</a
                            >
                          </li>
                          
                          <li>
                            <a href="/comingsoon">Services</a>
                          </li>
                          <li>
                            <a href="/comingsoon">Contact Us</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-6">
                      <div class="footer-widgets_title">
                        <h3>Our Hours</h3>
                      </div>
                      <div class="footer-widgets">
                        <ul>
                          <li><a href="javascript:void(0)">Monday - 10:00 AM to 6:00 PM</a></li>
                          <li><a href="javascript:void(0)">Tuesday - 10:00 AM to 6:00 PM</a></li>
                          <li><a href="javascript:void(0)">Wednesday - 10:00 AM to 6:00 PM</a></li>
                          <li><a href="javascript:void(0)">Thursday - 10:00 AM to 6:00 PM</a></li>
                          <li><a href="javascript:void(0)">Friday - 10:00 AM to 6:00 PM</a></li>
                          <li><a href="javascript:void(0)">Saturday - 10:00 AM to 6:00 PM</a></li>
                          <li><a href="javascript:void(0)">Sunday  - Closed</a></li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-6">
                      <div class="footer-widgets_title">
                        <h3>Contact Us</h3>
                      </div>
                      <div class="footer-widgets_info">
                        <div class="widgets-essential_stuff">
                    <ul>
                      <li class="uren-address">
                        <span><i class="far fa-map-marker-alt"></i> </span> 93 Plains rd west,Burlington,ON. L7T 1E8
                      </li>
                      <li class="uren-phone">
                        <span> <i class="far fa-phone-alt"></i> </span>
                        <a href="tel://(437) 779-4909"> (437) 779-4909</a>
                      </li>
                      <li class="uren-email">
                        <span><i class="far fa-envelope"></i> </span>
                        <a href="mailto://blackstarauto@gmail.com"> blackstarauto@gmail.com</a>
                      </li>
                    </ul>
                  </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer-bottom_area">
          <div class="container-fluid">
            <div class="footer-bottom_nav">
              <div class="row">
                <div class="col-lg-6 col-md-6">
                  <div class="copyright">
                    <span><a href="templateshub.net" style="color:gray">Copyright © 2023 Black Star Auto | All rights reserved</a></span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="payment">
                    <a href="#">
                      <img
                        src="assets/images/footer/payment/1.png"
                        alt="Uren's Payment Method"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Footer Area End Here -->
</template>
<script>
export default {
  name: 'FooterTag',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;      
    }
  },
  mounted() {
        
  },
   methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/").catch(() => {
        "FooterTag";
      });
    },
  },
};
</script>

