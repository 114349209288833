<template>
  <button ref="appButton" class="app-button" @click="scrollToTop()">
    <i class="fa fa-arrow-circle-up arrowIcon" aria-hidden="true"></i>
  </button>
</template>
<script>

export default {
  name: "AppButton",

  mounted() {
    window.addEventListener("scroll", this.userScroll);
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.userScroll);
  },

  methods: {
    userScroll() {
      if (window.scrollY > 0) {
        this.$refs.appButton.classList.add("showButton");
        // console.log("scrolled");
      } else {
        this.$refs.appButton.classList.remove("showButton");
        // console.log("top");
      }
    },
    scrollToTop  () {
  window.scrollTo({ top: 0, behavior: "smooth" });
}
  },
};
</script>
<style scoped>
.app-button {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 4px;
  /* box-shadow: 2px 2px #d4a4a4; */
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  display: none;
  right: 40px;
  background-color: #002868;
}

.showButton {
  display: block;
}
.arrowIcon {
    color: #dbdbdb;
    font-size: 22px;
}
</style>