<template>
    <div>
          <!-- Begin Banner one Area -->
      <section class="homeLoginSec" style="background-color:white;">

      

        <div class="container topbannerW"  >
        

          <div class="contt-wrapp">
        <div class="box">
            <h2>{{  !home_Page_Summary[0]? 0 : home_Page_Summary[0].totalLiveAuctions   }}</h2>
            <h3>Running Auctions </h3>
            <p>
              Welcome to our diverse vehicle auction platform for high-quality auctions.
            </p>
        </div>
        <div class="box">
            <h2>{{ !home_Page_Summary[0]? 0 : home_Page_Summary[0].totalVehicles }}</h2>
            <h3>Running Vehicles</h3>
            <p>
              Explore our vast inventory of running vehicles for an exceptional auction experience.
            </p>
        </div>
        <div class="box">
            <h2>{{ !home_Page_Summary[0]?0:home_Page_Summary[0].totalVehiclesSold }}</h2>
            <h3>Vehicles Sold</h3>
            <p>
              We have successfully sold a wide range of vehicles to satisfied customers.
            </p>
        </div>
    </div>

          <div class="row" style="display:none;">
            <div class="col-md-4">
              <div class="counter-top-new-wrap">
                
                  <img src="assets/images/h1.png" class="img-fluid">
                  <div class="counter-top-new-wrap-inner">
                    <h4>Running Auctions <span class="ru">({{  !home_Page_Summary[0]? 0 : home_Page_Summary[0].totalLiveAuctions   }})</span></h4>
                    <span>Welcome to our diverse vehicle auction platform for high-quality auctions.</span>
                  </div>
                
              </div>
            </div>
            <div class="col-md-4">
              <div class="counter-top-new-wrap">
                
                  <img src="assets/images/h1.png" class="img-fluid">
                  <div class="counter-top-new-wrap-inner">
                    <h4>Running Vehicles <span class="ru">({{ !home_Page_Summary[0]? 0 : home_Page_Summary[0].totalVehicles }})</span></h4>
                    <span>Explore our vast inventory of running vehicles for an exceptional auction experience.</span>
                  </div>
                
              </div>
            </div>
            <div class="col-md-4">
              <div class="counter-top-new-wrap">
                
                  <img src="assets/images/h1.png" class="img-fluid">
                  <div class="counter-top-new-wrap-inner">
                    <h4>Vehicles Sold <span class="ru">({{ !home_Page_Summary[0]?0:home_Page_Summary[0].totalVehiclesSold }})</span></h4>
                    <span>We have successfully sold a wide range of vehicles to satisfied customers</span>
                  </div>
                
              </div>
            </div>
          </div>


         

        </div>
      </section>
        <!-- End Banner one Area -->
      
    </div>
</template>

<script>
export default {

  props: {
    home_Page_Summary: Array,
    
  },

  
}
</script>
