<template>
  <div class="left-nav-menu-f">
    <div>
      <div class="wel-user">
        <img
          src="https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
        />
        <div>
          <a> Welcome</a>
          <span>{{ dasBoardData.sellerName }}</span>
        </div>
        <!-- <a><i class="fad fa-edit"></i></a> -->
      </div>
      <hr />

      <a class="toggle-i-p-m" @click="toggleList()">Menu</a>

      
      <ul
        v-if="showList"
        class="nav myaccount-tab-trigger"
        id="account-page-tab"
        role="tablist"
      >
        <!-- <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/vendorProfile"
            ><i class="fas fa-user-alt"> </i> My Profile</router-link
          >
        </li> -->
        <li class="nav-item active">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/VehicleUpload"
            >
            <i class="fa fa-plus-circle" aria-hidden="true"></i> Add
            Vehicles</router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/VehicleList"
            ><i class="fa fa-car" aria-hidden="true"></i> My Vehicles
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/ActiveAuctions"
            ><i class="fa fa-toggle-on" aria-hidden="true"></i> Active Auctions
          </router-link>
        </li>

          <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Upcoming"
            ><i class="fa-solid fa-spinner"></i> Upcoming Auctions
          </router-link>
        </li>

        <!-- <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/AuctionHistory"
            ><i class="fas fa-trophy"> </i> Auction History</router-link
          >
        </li> -->

       

        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Pending"
            ><i class="fa fa-clock" aria-hidden="true"></i> Pending Auctions
          </router-link>
        </li>


         


        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Closing"
            ><i class="fa fa-window-close" aria-hidden="true"></i> Closing
            Auctions
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Parked"
            ><i class="fa fa-map-marker" aria-hidden="true"></i> Parked Auctions
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/WonCustomerList"
            ><i class="fa fa-trophy" aria-hidden="true"></i> Won Auctions
            <span class="not-count">{{
              dasBoardData.wonAuction
            }}</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Arrived"
            ><i class="fa fa-location-arrow" aria-hidden="true"></i> Arrived
            Auctions
            <span class="not-count">{{
              dasBoardData.arrived
            }}</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Delivered"
            ><i class="fa fa-truck" aria-hidden="true"></i> Delivered Auctions
            <span class="not-count">{{
              dasBoardData.delivered
            }}</span></router-link
          >
        </li>
        <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-dashboard-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-dashboard"
            aria-selected="true"
            to="/Lost"
            ><i class="fa fa-ban" aria-hidden="true"></i> Lost Auctions
            <span
              style="background-color: #e72c2c; !important"
              class="not-count"
              >{{ dasBoardData.lost }}</span
            ></router-link
          >
        </li>
        <!-- <li class="nav-item">
                    <a
                      class="nav-link"
                      id="account-packages-tab"
                      data-toggle="tab"
                      href="#account-packages"
                      role="tab"
                      aria-controls="account-packages"
                      aria-selected="false"
                      ><i class="fas fa-cubes"></i> Packages</a
                    >
                  </li> -->
        <!-- <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-address-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-address"
            aria-selected="false"
            to="/vendorChangePassword"
            ><i class="fas fa-key"> </i> Change Password</router-link
          >
        </li> -->

        <!-- <li class="nav-item">
                    <a
                      class="nav-link"
                      id="account-help-tab"
                      data-toggle="tab"
                      href="#account-help"
                      role="tab"
                      aria-controls="account-orders"
                      aria-selected="false"
                      ><i class="fas fa-headphones-alt"></i> Help</a
                    >
                  </li> -->
        <!-- <li class="nav-item">
          <router-link
            class="nav-link"
            id="account-address-tab"
            data-toggle="tab"
            role="tab"
            aria-controls="account-address"
            aria-selected="false"
            to="/settings"
            ><i class="fas fa-cogs"></i> Settings</router-link
          >
        </li> -->

        <!-- <li class="nav-item">
                    <a
                      class="nav-link"
                      id="account-address-tab"
                      data-toggle="tab"
                      href="/settings"
                      role="tab"
                      aria-controls="account-address"
                      aria-selected="false"
                      ><i class="fas fa-cogs"></i> Settings</a
                    >
                  </li> -->

        <!-- <li class="nav-item" v-if="currentUser">
          <a
            class="nav-link"
            id="account-details-tab"
            href
            @click.prevent="logOut"
            role="tab"
            ><i class="fas fa-lock"></i> Sign Out</a
          >
        </li> -->
      </ul>
      <!-- <hr/> -->
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
export default {
  name: "SideMenu",
  

  data() {
    return {
      dasBoardData: [],
      currentUrl: window.location.href,
      showList: true,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    var className = document.getElementsByClassName("nav-link");
    for (var i = 0; i < className.length; i++) {
      if (className[i].className.includes("router-link-active")) {
        if (!this.currentUrl.includes("?vin")) {
          className[i].classList.add("active");
        }
      }
    }
    this.userinfo = JSON.parse(localStorage.getItem("user"));

    this.getDashboardProfileFe(this.userinfo.sellerId, "vendor");
    //getDashboardProfileFe
  },
  methods: {
    toggleList() {
      this.showList = !this.showList;
    },
    logOut() {
      this.userRole = "";
      this.$store.dispatch("auth/logout");
      this.$router.push("/").catch(() => {
        "sidemenuVendor";
      });
    },
    getDashboardProfileFe(custId, type) {
      if (custId == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getDashboardProfileFe(custId, type)
          .then((response) => {
            this.dasBoardData = response.data.Data[0];
            //console.log(this.dasBoardData);
            window.scrollTo(0, 0);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>
<style scoped>
.nav-item a:hover {
  background-color: #002868 !important;
  color: white !important;
}
</style>
