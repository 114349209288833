<template>
  <ul class="countdown">
    <div style="background-color: #ededed !important; height:24px!important;padding:0px 10px!important;" class="countdownlefttop">
      <li v-if="days > 0">
        <p class="digit">
          {{ days }} <span>{{ days > 1 ? "D" : "D" }}:</span>
        </p>
      </li>
      <li>
        <p class="digit">
          {{ hours }} <span>{{ hours > 1 ? "H" : "H" }}:</span>
        </p>
      </li>
      <li>
        <p class="digit">{{ minutes  }} <span>M:</span></p>
      </li>
      <li>
        <p class="digit">{{ seconds  }} <span>S</span></p>
      </li>
    </div>
  </ul>
</template>

<script>
let interval = null;
import moment from "moment";
import { string } from 'yup/lib/locale';
export default {
  name: "CountDown",
  props: {
    deadline: String,
    end: {
      type: String,
    },
    stop: {
      type: Boolean,
    },
    customstyle :string,
  },
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      date: null,
      diff: 0,
    };
  },
  created() {
    let dateauc = moment(String(this.deadline)).format("YYYY-MM-DD HH:mm");
    if (!dateauc && !this.end) {
      throw new Error("Missing props 'deadline' or 'end'");
    }
    let deadline = dateauc;
      // console.log(deadline);sss
    let endTime = deadline ? deadline : this.end;
    //  console.log(endTime);
    this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);

    if (!this.date) {
      throw new Error("Invalid props value, correct the 'deadline' or 'end'");
    }

    interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  //    mounted(){
  //          console.log(moment(String(this.deadline)).format('YYYY-MM-DD HH:mm'));
  //    },
  updated() {
    let dateauc = moment(String(this.deadline)).format("YYYY-MM-DD HH:mm");
    if (!dateauc && !this.end) {
      throw new Error("Missing props 'deadline' or 'end'");
    }
    let deadline = dateauc;

    let endTime = deadline ? deadline : this.end;
    //  console.log(endTime);
    this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);

    if (!this.date) {
      throw new Error("Invalid props value, correct the 'deadline' or 'end'");
    }

    interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  computed: {
    seconds() {
      return Math.trunc(this.diff) % 60;
    },

    minutes() {
      return Math.trunc(this.diff / 60) % 60;
    },

    hours() {
      return Math.trunc(this.diff / 60 / 60) % 24;
    },

    days() {
      return Math.trunc(this.diff / 60 / 60 / 24);
    },
    
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    },
  
  },
  watch: {
    now(value) {
      this.diff = this.date - this.now;
      if (this.diff <= 0 || this.stop) {
        this.diff = 0;
        // Remove interval
        // console.log(value)
        this.blank_value = value;
        clearInterval(interval);
      }
    },
  },
  
  unmounted() {
    clearInterval(interval);
  },
};
</script>
