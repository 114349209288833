import { createWebHistory, createRouter } from "vue-router";
// common routes
import Home from "./components/Home.vue";
// customer routes
import customerLogin from "./components/customer/customerLogin.vue";
import test from "./views/Vendor/test.vue";
import comingsoon from "./components/comingsoon.vue";
import customerRegister from "./components/customer/customerRegister.vue";
// lazy-loaded
const customerProfile = () =>
  import("./components/customer/customerProfile.vue");
const Auction = () => import("./components/customer/auction.vue");
const TermsCustomer = () => import("./components/customer/TermsCustomer.vue");
const Bids = () => import("./views/Customer/Bids.vue");
const Winnings = () => import("./views/Customer/Winnings.vue");
const Wishlist = () => import("./views/Customer/Wishlist.vue");
const ProcessWinningsCustomer = () =>
  import("./views/Customer/ProcessWinningsCustomer.vue");
const Changepassword = () => import("./views/Customer/ChangePassword.vue");
const CustomerForgotPassword = () =>
  import("./components/customer/customerForgotPassword.vue");
const Settings = () => import("./views/Customer/Settings.vue");
const ContactUs = () => import("./components/contactUs.vue");
const About = () => import("./components/about.vue");

const PendingCustomer = () => import("./views/Customer/Pending.vue");
const ParkedCustomer = () => import("./views/Customer/Parked.vue");
const ArrivedCustomer = () => import("./views/Customer/Arrived.vue");
const DeliveredCustomer = () => import("./views/Customer/Delivered.vue");
const LostCustomer = () => import("./views/Customer/Lost.vue");
const ClosingCustomer = () => import("./views/Customer/Closing.vue");
const WonCustomer = () => import("./views/Customer/WonCustomer.vue");
const Research = () => import("./views/Customer/Research.vue");
// Vendor routes
import vendorLogin from "./components/vendor/vendorLogin.vue";
import TermsVendor from "./components/vendor/TermsVendor.vue";
import vendorRegister from "./components/vendor/vendorRegister.vue";
const vendorForgotPassword = () =>
  import("./components/vendor/vendorForgotPassword.vue");
const vendorProfile = () => import("./components/vendor/vendorProfile.vue");
const ActiveAuctions = () => import("./views/Vendor/ActiveAuctions.vue");
const AddVehicles = () => import("./views/Vendor/AddVehicles.vue");
const AuctionHistory = () => import("./views/Vendor/AuctionHistory.vue");
const Pending = () => import("./views/Vendor/Pending.vue");

const Upcoming = () => import("./views/Vendor/UpcomingAuctions.vue");

const Parked = () => import("./views/Vendor/Parked.vue");
const Arrived = () => import("./views/Vendor/Arrived.vue");
const Delivered = () => import("./views/Vendor/Delivered.vue");
const Lost = () => import("./views/Vendor/Lost.vue");
const Closing = () => import("./views/Vendor/Closing.vue");
const UpdateVehicles = () => import("./views/Vendor/UpdateVehicles.vue");
const VehicleList = () => import("./views/Vendor/VehicleList.vue");
const WonListVendor = () => import("./views/Vendor/WonVendor.vue");
const VehicleDetails = () => import("./views/Vendor/VehicleDetails.vue");
const VehDetails = () => import("./views/Vendor/VehDetails.vue");
const VehicleDeailsPending = () =>
  import("./views/Vendor/VehicleDeailsPending.vue");
const vendorChangePassword = () =>
  import("./views/Vendor/vendorChangePassword.vue");
const AuctionDetails = () => import("./components/vendor/AuctionDetails.vue");
const VehicleUpload = () => import("./views/Vendor/VehicleUpload.vue");
const Dasboard = () => import("./components/testComponents/Dashboard.vue");
// EditVehcle
const EditVehcle = () => import("./views/Vendor/EditVehcle.vue");

const routes = [
  {
    // common route
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/test",
    component: test,
  },
  {
    path: "/comingsoon",
    component: comingsoon,
  },

  // customer route
  {
    path: "/customerlogin",
    component: customerLogin,
  },
  {
    path: "/customerRegister",
    component: customerRegister,
  },
  {
    path: "/customerForgotpassword",
    name: "customerForgotpassword",
    // lazy-loaded
    component: CustomerForgotPassword,
  },
  {
    path: "/customerprofile",
    name: "customerprofile",
    // lazy-loaded
    component: customerProfile,
  },
  {
    path: "/auction",
    name: "auction",
    // lazy-loaded
    props: true,
    component: Auction,
  },
  {
    path: "/bids",
    name: "bids",
    // lazy-loaded
    component: Bids,
  },
  {
    path: "/winnings",
    name: "winnings",
    // lazy-loaded
    component: Winnings,
  },
  {
    path: "/Wishlist",
    name: "Wishlist",
    // lazy-loaded
    component: Wishlist,
  },
  {
    path: "/ProcessWinningsCustomer",
    name: "ProcessWinningsCustomer",
    // lazy-loaded
    component: ProcessWinningsCustomer,
  },
  {
    path: "/settings",
    name: "settings",
    // lazy-loaded
    component: Settings,
  },
  {
    path: "/changepassword",
    name: "changepassword",
    // lazy-loaded
    component: Changepassword,
  },
  {
    path: "/contactus",
    name: "contactus",
    // lazy-loaded
    component: ContactUs,
  },
  {
    path: "/about",
    name: "about",
    // lazy-loaded
    component: About,
  },
  {
    path: "/TermsCustomer",
    name: "TermsCustomer",
    // lazy-loaded
    component: TermsCustomer,
  },
  {
    path: "/AuctionDetails",
    name: "AuctionDetails",
    // lazy-loaded
    component: AuctionDetails,
    props: true,
  },
  {
    path: "/PendingCustomer",
    name: "PendingCustomer",
    // lazy-loaded
    component: PendingCustomer,
  },
  {
    path: "/ClosingCustomer",
    name: "ClosingCustomer",
    // lazy-loaded
    component: ClosingCustomer,
    props: true,
  },
  {
    path: "/ParkedCustomer",
    name: "ParkedCustomer",
    // lazy-loaded
    component: ParkedCustomer,
    props: true,
  },
  {
    path: "/DeliveredCustomer",
    name: "DeliveredCustomer",
    // lazy-loaded
    component: DeliveredCustomer,
    props: true,
  },
  {
    path: "/ArrivedCustomer",
    name: "ArrivedCustomer",
    // lazy-loaded
    component: ArrivedCustomer,
    props: true,
  },
  {
    path: "/LostCustomer",
    name: "LostCustomer",
    // lazy-loaded
    component: LostCustomer,
    props: true,
  },
  {
    path: "/WonCustomer",
    name: "WonCustomer",
    // lazy-loaded
    component: WonCustomer,
  },
  {
    path: "/Research",
    name: "Research",
    // lazy-loaded
    component: Research,
  },
  // vendor routes
  {
    path: "/vendorLogin",
    component: vendorLogin,
  },
  {
    path: "/vendorRegister",
    component: vendorRegister,
  },
  {
    path: "/vendorForgotPassword",
    name: "vendorForgotPassword",
    // lazy-loaded
    component: vendorForgotPassword,
  },
  {
    path: "/vendorChangePassword",
    name: "vendorChangePassword",
    // lazy-loaded
    component: vendorChangePassword,
  },
  {
    path: "/vendorprofile",
    name: "vendorprofile",
    // lazy-loaded
    component: vendorProfile,
  },
  {
    path: "/VehicleList",
    name: "VehicleList",
    // lazy-loaded
    component: VehicleList,
  },
  {
    path: "/ActiveAuctions",
    name: "ActiveAuctions",
    // lazy-loaded
    component: ActiveAuctions,
  },
  {
    path: "/AddVehicles",
    name: "AddVehicles",
    // lazy-loaded
    component: AddVehicles,
  },
  {
    path: "/VehicleUpload",
    name: "VehicleUpload",
    // lazy-loaded
    component: VehicleUpload,
  },
  {
    path: "/EditVehcle",
    name: "EditVehcle",
    // lazy-loaded
    component: EditVehcle,
  },
  {
    path: "/testDash",
    name: "testDash",
    // lazy-loaded
    component: Dasboard,
  },

  {
    path: "/AuctionHistory",
    name: "AuctionHistory",
    // lazy-loaded
    props: true,
    component: AuctionHistory,
  },
  {
    path: "/Pending",
    name: "Pending",
    // lazy-loaded
    component: Pending,
  },
  {
    path: "/Upcoming",
    name: "Upcoming",
    // lazy-loaded
    component: Upcoming,
  },
  {
    path: "/Closing",
    name: "Closing",
    // lazy-loaded
    component: Closing,
    props: true,
  },
  {
    path: "/Parked",
    name: "Parked",
    // lazy-loaded
    component: Parked,
    props: true,
  },
  {
    path: "/Delivered",
    name: "Delivered",
    // lazy-loaded
    component: Delivered,
    props: true,
  },
  {
    path: "/Arrived",
    name: "Arrived",
    // lazy-loaded
    component: Arrived,
    props: true,
  },
  {
    path: "/Lost",
    name: "Lost",
    // lazy-loaded
    component: Lost,
    props: true,
  },
  {
    path: "/UpdateVehicles",
    name: "UpdateVehicles",
    // lazy-loaded
    component: UpdateVehicles,
    props: true,
  },
  {
    path: "/VehicleDetails",
    name: "VehicleDetails",
    // lazy-loaded
    component: VehicleDetails,
    props: true,
  },
  {
    path: "/VehDetails",
    name: "VehDetails",
    // lazy-loaded
    component: VehDetails,
    props: true,
  },
  {
    path: "/VehicleDeailsPending",
    name: "VehicleDeailsPending",
    // lazy-loaded
    component: VehicleDeailsPending,
    props: true,
  },

  {
    path: "/WonCustomerList",
    name: "WonCustomerList",
    // lazy-loaded
    component: WonListVendor,
  },
  {
    path: "/TermsVendor",
    name: "TermsVendor",
    // lazy-loaded
    component: TermsVendor,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/customerlogin",
    "/TermsVendor",
    "/TermsCustomer",
    "/customerRegister",
    "/customerForgotpassword",
    "/vendorForgotPassword",
    "/",
    "/test",
    "/auction",
    "/contactus",
    "/about",
    "/comingsoon",
    "/testDash",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/");
  } else {
    next();
  }
});

export default router;
