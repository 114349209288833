<template>
  <div>
    <div class="card card-container registerFormwrap">
      <div>
        <div class="row">
          <div class="col-md-5">
            <h4>Seller Register</h4>
          </div>
          <div class="col-md-7">
            <h4>
              <!-- <a href="/customerRegister">Register as Customer</a> -->
            </h4>
          </div>
          <p style="padding-left: 15px">Please register to continue</p>
        </div>
      </div>
      <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
        <div class="form-row row">
          <div class="col-md-6">
            <div>
              <div class="form-group col">
                <label>First Name</label>
                <Field
                  name="firstName"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.firstName }"
                  placeholder="Enter your first name"
                />
                <div class="invalid-feedback">{{ errors.firstName }}</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div>
              <div class="form-group col">
                <label>Last Name</label>
                <Field
                  name="lastName"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.lastName }"
                  placeholder="Enter your last name"
                />
                <div class="invalid-feedback">{{ errors.lastName }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div>
              <div class="form-group col">
                <label>Email</label>
                <Field
                  name="email"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email }"
                  placeholder="Enter your Email ID"
                />
                <div class="invalid-feedback">{{ errors.email }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div>
              <div class="form-group col">
                <label>Phone</label>
                <div class="">
                  <div class="makeinside countryFlagimg">
                    <img
                      src="/assets/images/menu/flag/897.png"
                      alt="Canada Flag"
                      class=""
                      style=""
                    /><span>+1</span>
                  </div>
                  <!-- <div class="col-md-1 coutryCode">
                          <label for="phone" class="" style="font-size: 15px;"> +1</label>
                        </div> -->
                  <div class="phoneNUmberCls">
                    <Field
                      name="phone"
                      type="number"
                      class="form-control"
                      :class="{ 'is-invalid': errors.phone }"
                      placeholder="Enter your phone number"
                    />
                    <div class="invalid-feedback">{{ errors.phone }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div>
              <div class="form-group col">
                <label>Id Proof No</label>
                <Field
                  name="idnumber"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.idnumber }"
                  placeholder="Enter your ID proof number"
                />
                <div class="invalid-feedback">{{ errors.idnumber }}</div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="">
              <div class="form-group col">
                <label>Id Proof (*Image Only) </label>
                <Field
                  style="padding: 3px"
                  name="fileupload"
                  type="file"
                  accept="image/png,image/jpeg"
                  class="form-control"
                  @change="onFileChange"
                  :class="{ 'is-invalid': errors.fileupload }"
                />
                <div class="invalid-feedback">
                  {{ errors.fileupload }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div>
              <div class="form-group col">
                <label>Password</label>
                <div style="position: relative">
                  <Field
                    name="password"
                    :type="passwordCls"
                    class="form-control"
                    :class="{ 'is-invalid': errors.password }"
                    placeholder="Enter your password"
                  />
                  <div class="invalid-feedback">
                    {{ errors.password }}
                  </div>
                  <button
                    type="button"
                    @click="changeType()"
                    id="btnToggle"
                    class="toggle passeye"
                  >
                  <i id="eyeIcon" v-if="passwordCls == 'text'" class="fas fa-eye-slash"></i>
                    <i id="eyeIcon" v-if="passwordCls == 'password'" class="fa fa-eye"></i>
                  
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div>
              <div class="form-group col">
                <label>Confirm Password</label>
                <div style="position: relative">
                  <Field
                    name="confirmPassword"
                    :type="passwordClsC"
                    class="form-control"
                    :class="{
                      'is-invalid': errors.confirmPassword,
                    }"
                    placeholder="Confirm your password"
                  />
                  <div class="invalid-feedback">
                    {{ errors.confirmPassword }}
                  </div>
                  <button
                    type="button"
                    id="btnToggle"
                    @click="changeTypeC()"
                    class="toggle passeye"
                  >
                    <i id="eyeIcon" v-if="passwordClsC == 'text'" class="fas fa-eye-slash"></i>
                    <i id="eyeIcon" v-if="passwordClsC == 'password'" class="fa fa-eye"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group col">
              <div class="form-group form-check">
                <Field
                  name="acceptTerms"
                  type="checkbox"
                  id="acceptTerms"
                  value="true"
                  class="form-check-input"
                  :class="{ 'is-invalid': errors.acceptTerms }"
                />
                <label for="acceptTerms" class="form-check-label">
                  <a
                    href="/TermsVendor"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Accept Terms & Conditions</a
                  >
                </label>
                <div class="invalid-feedback">{{ errors.acceptTerms }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button
            style="background-color: #bf0a30; color: white"
            class="btn btn-primary btn-block"
            :disabled="loading"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            REGISTER
          </button>
        </div>
      </Form>
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
      <h6 class="text-center reg-to-log">
        If you are already registered ? Please
        <a href="/customerlogin">Login here</a>
      </h6>
    </div>
  </div>
  <!--  </div>
      </div>
    </div>
  </section> -->
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";

export default {
  name: "vendorRegister",
  components: {
    Form,
    Field,
    // ErrorMessage,
  },
  data() {
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const schema = Yup.object().shape({
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
      phone: Yup.string()
        .required("Phone number is required")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Phone number is too short")
        .max(10, " Phone number is too long"),

      //Yup.string().matches(/^[6-9]\d{9}$/, {message: "Please enter valid number.", excludeEmptyString: false}),
      //Yup.number("Must be Number")
      // .min(6000000000, "Must be more than 10 characters")
      //.required("This field is requried")
      // .max(9999999999, "Number is Too Long!"),
      idnumber: Yup.string().required("Id proof  number is required"),
      fileupload: Yup.mixed()
        .required("Id proof  is required")
        .test(
          "fileType",
          "Upload  PNG  or JPEG.",
          function (value) {
            if (value) {
              const allowedTypes = ["image/png", "image/jpeg"];
              return allowedTypes.includes(value.type);
            }
            //return true; // Skip validation if no file is uploaded
          }
        ),
      acceptTerms: Yup.string().required("Accept Ts & Cs is required"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
      images: [],
      passwordCls: "password",
      passwordClsC: "password",
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/vendorprofile");
    }
  },
  methods: {
    changeType() {
      this.passwordCls = this.passwordCls == "password" ? "text" : "password";
    },
    changeTypeC() {
      this.passwordClsC = this.passwordClsC == "password" ? "text" : "password";
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files);
    },
    createImage(files) {
      var vm = this;

      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.images.push(imageUrl);
        };
        reader.readAsDataURL(files[index]);
      }
    },
    onSubmit(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      if (this.images[0]) {
        user.image = this.images[0];
        this.$store.dispatch("auth/vendorregister", user).then(
          (data) => {
            if (data.Message == "Success") {
              this.message =
                "Registration completed successfully, you can login once the Admin approves";
            }
            this.successful = true;
            this.loading = false;
            this.goToLogin();
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.Message) ||
              error.message ||
              error.toString();
            this.successful = false;
            this.loading = false;
          }
        );
      } else {
        this.successful = false;
        this.loading = false;
        this.message = "Please add an image";
      }
    },
    goToLogin() {
      setTimeout(() => this.$router.push("/customerlogin"), 5000);
    },
  },
};
</script>
