import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createPinia } from "pinia";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "./plugins/font-awesome";

import i18n from "@/i18n";
import Toaster from "@meforma/vue-toaster";
import scrollToTopButton from "@/components/modules/scrollToTopButton.vue";

const pinia = createPinia();
createApp(App)
  .use(router)
  .use(store)

  .use(i18n)
  .use(Toaster)

  .component("scrollToTopButton", scrollToTopButton)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(pinia)
 
  .mount("#app");
