<template>
  <div v-if="VehicleDetails.length !== 0">
    <div class="new-main-tile">
      <section style="padding: 60px 0px; background-color: #f1f1f1">
        <div class="container" v-if="VehicleDetails.length !== 0">
          <div class="text-center">
            <h3 class="mb-30" style="color: black">{{ AuctionName }}</h3>
          </div>

          <Carousel
            v-if="VehicleDetails.length !== 0"
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <Slide
              v-for="(VehicleDetail, index) in VehicleDetails"
              :key="index"
            >
              <div class="carousel__item">
                <section>
                  <div class="" style="margin: 0px 6px">
                    <div class="">
                      <div class="carTileWrap shine-overlay">
                        <div class="shine"></div>
                        <div class="cwrapImage" style="position: relative">
                          <router-link
                            :to="{
                              name: routerPath,
                              params: { url_key: VehicleDetail.aucName },
                            }"
                          >
                            <img
                              :src="path + VehicleDetail.vehImage1"
                              class="img-fluid img-responsive border5px"
                          /></router-link>

                          <div class="topleft">
                            <div>
                              <CountDown
                                :customstyle="'background-color: beige;'"
                                :deadline="VehicleDetail.aucExtDate"
                              ></CountDown>
                            </div>
                          </div>
                          <div class="topright" v-if="!userinfo">
                            <div style="display: flex">
                              <!-- Wishlist -->
                              <div class="bidlistDetailswrapwidth15">
                                <div v-if="VehicleDetail.isWishlisted == 1">
                                  <div
                                    class="button-bidzzz active"
                                    @click="rmvfromWhishlist(VehicleDetail)"
                                  >
                                    <svg
                                      width="35px"
                                      height="25px"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g fill="none" fill-rule="evenodd">
                                        <path
                                          class="heart-stroke"
                                          d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                          fill="#0090E3"
                                        />
                                        <path
                                          class="heart-full"
                                          d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                          fill="red"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M26,4 L30.6852129,0.251829715"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M2.314788,4 L7.00000086,0.251829715"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 10.314788 1)"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M27,12 L33,12"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M0,12 L6,12"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 7 1)"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M24,19 L28.6852129,22.7481703"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M4.314788,19 L9.00000086,22.7481703"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 14.314788 1)"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                                <div
                                  v-else-if="VehicleDetail.isWishlisted == 0"
                                >
                                  <div
                                    class="button-bidzzz"
                                    @click="addorRemove(VehicleDetail)"
                                  >
                                    <svg
                                      width="35px"
                                      height="25px"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g fill="none" fill-rule="evenodd">
                                        <path
                                          class="heart-stroke"
                                          d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                          fill="#0090E3"
                                        />
                                        <path
                                          class="heart-full"
                                          d="M13.0185191,4.25291223 L12.9746137,4.25291223 C10.1097846,4.25291223 8.67188189,6.6128289 8.5182129,8.92335198 C8.39747298,10.6740809 8.73225185,12.8528876 14.0777375,18.4782704 C14.7127154,19.1080239 15.5654911,19.4695694 16.4596069,19.4880952 C17.3247917,19.4700909 18.1444718,19.0969678 18.7262246,18.4563177 C19.3189478,17.9074999 24.5052763,12.5894551 24.3570955,8.98921012 C24.2363556,6.42623084 22.123407,4.25291223 19.7525139,4.25291223 C18.5053576,4.22947431 17.3125171,4.76253118 16.4980242,5.70727948 C15.6177331,4.73767759 14.354699,4.20555668 13.04596,4.25291223 L13.0185191,4.25291223 Z"
                                          fill="red"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M26,4 L30.6852129,0.251829715"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M2.314788,4 L7.00000086,0.251829715"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 10.314788 1)"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M27,12 L33,12"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M0,12 L6,12"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 7 1)"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M24,19 L28.6852129,22.7481703"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                        />
                                        <path
                                          class="heart-lines"
                                          d="M4.314788,19 L9.00000086,22.7481703"
                                          stroke="red"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          transform="matrix(-1 0 0 1 14.314788 1)"
                                        />
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <!-- <div style="margin-right: 5px">
                            <i
                              class="fa fa-heart"
                              style="
                                background-color: white; 
                                padding: 5px;
                                border-radius: 50%;
                              "
                            ></i>
                          </div> -->
                              <!-- Share -->
                              <!-- <div>
                            <i
                              class="fas fa-share-alt"
                              style="
                                background-color: white;
                                padding: 5px;
                                border-radius: 50%;
                              "
                            ></i>
                          </div> -->
                            </div>
                          </div>
                        </div>
                        <div>
                          <div class="vehicleName">
                            <router-link
                              :to="{
                                name: routerPath,
                                params: { url_key: VehicleDetail.aucName },
                              }"
                            >
                              <h5>
                                {{ VehicleDetail.brandName }}
                                {{ VehicleDetail.modelName }}
                                {{ VehicleDetail.manYear }}
                              </h5>
                            </router-link>
                          </div>
                          <div>
                            <div class="row" style="margin: 0px">
                              <div class="col-6">
                                <div>
                                  <i
                                    class="fad fa-tachometer-alt-fast pdtListico"
                                  ></i>
                                  <div class="pdtListicon">
                                    <span>Odometer</span>
                                    <p class="fontWeightbidlist">
                                      {{ VehicleDetail.kmClocked }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div>
                                  <i
                                    class="fad fa-map-marker-alt pdtListico"
                                  ></i>
                                  <div class="pdtListicon">
                                    <span>Location</span>
                                    <p class="fontWeightbidlist">
                                      {{ VehicleDetail.locName }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div>
                                  <i class="fad fa-cars pdtListico"></i>
                                  <div class="pdtListicon">
                                    <span>Trim Level</span>
                                    <p class="fontWeightbidlist">Base</p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div>
                                  <i
                                    class="fad fa-comment-alt-exclamation pdtListico"
                                  ></i>
                                  <div class="pdtListicon">
                                    <span>Disclosure</span>
                                    <p class="fontWeightbidlist">
                                      Not Available
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div>
                                  <i class="fad fa-gas-pump pdtListico"></i>
                                  <div class="pdtListicon">
                                    <span>Mileage</span>
                                    <p class="fontWeightbidlist">Not Actual</p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-6">
                                <div>
                                  <i class="fad fa-badge-check pdtListico"></i>
                                  <div class="pdtListicon">
                                    <span>As Is</span>
                                    <p class="fontWeightbidlist">
                                      Not Available
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr style="margin: 0px" />
                            <div class="row">
                              <div class="col-12">
                                <div class="text-center">
                                  <div>
                                    <p class="tile-small-txt">
                                      CURRENT BID PRICE
                                    </p>
                                    <h4
                                      style="font-size: 18px; font-weight: 600"
                                    >
                                      {{
                                        $n(
                                          VehicleDetail.latestBidAmount,
                                          "currency",
                                          language
                                        )
                                      }}
                                    </h4>
                                    <router-link
                                      v-if="custId"
                                      class="tile-bid-now"
                                      :to="{
                                        name: routerPath,
                                        params: {
                                          url_key: VehicleDetail.aucName,
                                        },
                                      }"
                                    >
                                      View Details
                                    </router-link>
                                  </div>
                                </div>
                              </div>
                              <!-- <div class="col-6">
                                <div class="text-center">
                           
                                </div>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- .container -->
                </section>
              </div>
            </Slide>

            <template #addons>
              <!-- <Pagination /> -->
              <Navigation />
            </template>
          </Carousel>
        </div>
        <!-- .container -->
      </section>
    </div>
    <div class="overlay" v-if="openModel">
      <!-- modal -->
      <button class="close" @click="openModel = false">x</button>
      <h3>Title</h3>
      <p>Description : {{ amount }}</p>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user.service";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
// import VehicleCard from "../modules/VehicleCard.vue";
import CountDown from "./CountDown.vue";
import Config from "@/config.js";
export default {
  name: "AuctionSlider",
  components: {
    Carousel,
    Slide,
    CountDown,
    // VehicleCard,
    // Pagination,
    Navigation,
  },
  props: {
    VehicleDetails: Array,
    AuctionName: String,
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    if (this.userinfo) {
      if (typeof this.userinfo.customerId != "undefined") {
        this.custId = this.userinfo.customerId;
        this.routerPath = "AuctionDetails";
      }
    }
  },
  methods: {
    // showModal(e) {
    //   this.openModel = !this.openModel;
    //   console.log(e);
    //   this.amount = e.latestBidAmount;
    // },
    addorRemove(elem) {
      if (this.userinfo) {
        this.addWhishlist(elem);
      } else {
        this.$router.push("/").catch(() => {
          "carlist";
        });
      }
    },
    addWhishlist(elem) {
      userService.addToWhishlist(this.custId, elem).then((response) => {
        this.message = response.data.Message;
        this.$emit("wishList");
      });
    },
    rmvfromWhishlist(item) {
      userService.rmvfromWhishlist(item, this.custId).then((response) => {
        this.message = response.data.Message;
        this.$emit("wishList");
      });
    },
  },
  data() {
    return {
      path: Config.BASE_URL_IMG,
      openModel: false,
      amount: "",
      userinfo: "",
      custId: "",
      message: "",
      routerPath: "",

      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        autoplay: 4000,
        wrapAround: true,
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
      language: Config.BASE_LANG,
    };
  },
};
</script>
<style scoped>
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
.heart-stroke {
  fill: none;
  stroke: #666;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
  animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
  animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
  animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
