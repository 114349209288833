<template>
  <!-- Begin Banner Two Area -->
  <div
    class="uren-banner_area uren-banner_area-4 parallax-set-auto"
    style="background-color: white; padding: 60px 15px"
  >
  <div class="container">
      <!-- <div class="" style="text-align: center; margin-bottom: 15px">
        <h3 style="color: black; ">How this works?</h3>
      </div> -->
      <div class="row" v-if="videoUrl.length !== 0">
        <div class="col-md-6" v-for="(video, index) in videoUrl" :key="index">
          <div>
            <div class="video-wrapper">
              <div class="video-container" id="video-container">
                <video
                  controls
                  id="video"
                  preload="metadata"
                  :poster="video.posterImage"
                  class="videPlayer"
                >
                  <source :src="video.video_Url" type="video/mp4" />
                </video>

                <!-- <div class="play-button-wrapper">
                  <div
                    title="Play video"                   
                    class="play-gif"
                    id="circle-play-b"
                    @click="playVideo($event)"
                  >
                  
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                      <path
                        d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z"
                      />
                    </svg>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <!--  -->
      </div>
    </div>
  </div>
  <!--  Banner Two Area End Here -->
</template>
<script>
export default {
  name: "BannerCard",

  props: {
    bannserImages: Array,
    bannerStyle: String,
  },

  data() {
    return {
      videoUrl: [
        {
          posterImage: "assets/images/how-to-buy.png",
          video_Url:
            "//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4",
        },
        {
          posterImage: "assets/images/how-to-sell.png",
          video_Url:
            "//cdn.jsdelivr.net/npm/big-buck-bunny-1080p@0.0.6/video.mp4",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>
