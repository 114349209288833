<template>
  <navbar></navbar>
  <section class="loginpagebackground">
    <div class="container">
      <div class="row">
        <div class="col-md-4" style="margin-top: 60px; margin-bottom: 60px">
          <div>
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  data-toggle="tab"
                  href="#tabs-1"
                  role="tab"
                  >Customer</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab"
                  >Seller</a
                >
              </li>
            </ul>
            <!-- Tab panes -->
            <div class="tab-content">
              <div class="tab-pane active" id="tabs-1" role="tabpanel">
                <div>
                  <div class="card card-container loginFormwrap">
                    <div>
                      <div>
                        <h4>Customer Login</h4>
                        <p>Please login to continue</p>
                      </div>
                    </div>
                    <Form @submit="handleLogin" :validation-schema="schema">
                      <div class="form-group">
                        <label for="username">Username (Email ID)</label>
                        <Field
                          name="username"
                          type="text"
                          class="form-control"
                          placeholder="Enter your username"
                        />
                        <ErrorMessage name="username" class="error-feedback" />
                      </div>
                      <div class="form-group">
                        <label for="password">Password</label>
                        <div style="position: relative">
                          <Field
                            name="password"
                            :type="passwordCls"
                            class="form-control"
                            placeholder="Enter your password"
                          />
                          <button
                            type="button"
                            id="btnToggle"
                            class="toggle passeye"
                            @click="changeType()"
                          >
                          <i
                                    id="eyeIcon"
                                    v-if="passwordCls == 'text'"
                                    class="fas fa-eye-slash"
                                  ></i>
                                  <i
                                    id="eyeIcon"
                                    v-if="passwordCls == 'password'"
                                    class="fa fa-eye"
                                  ></i>
                          </button>
                        </div>
                        <ErrorMessage name="password" class="error-feedback" />
                      </div>

                      <div class="form-group">
                        <button
                          style="background-color: #bf0a30; color: white"
                          class="btn btn-block"
                          :disabled="loading"
                        >
                          <span
                            v-show="loading"
                            class="spinner-border spinner-border-sm"
                          ></span>
                          <span>LOGIN</span>
                        </button>
                      </div>
                      <div>
                        <div class="row">
                          <div class="col-12 text-center">
                            <router-link
                              to="customerForgotpassword"
                              style="color: black; text-decoration: none"
                              ><span class="forgot-txt">Forgot Password?</span>
                            </router-link>
                            <h6 class="signup-text">
                              Don't have an account?
                              <router-link
                                to="customerRegister"
                                style="color: blue; text-decoration: none"
                              >
                                Sign Up
                              </router-link>
                            </h6>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <div
                          v-if="message"
                          class="alert alert-danger"
                          role="alert"
                        >
                          {{ message }}
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tabs-2" role="tabpanel">
                <vendorLogin></vendorLogin>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import navbar from "@/components/navbar.vue";
import vendorLogin from "../vendor/vendorLogin.vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "CustomerLogin",
  components: {
    Form,
    Field,
    ErrorMessage,
    vendorLogin,
    navbar,
  },
  data() {
    const schema = yup.object().shape({
      username: yup.string().required("Username is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
      passwordCls: "password",
      userinfo: this.$store.state.auth.user,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      if (this.userinfo != null) {
        if (typeof this.userinfo.customerId != "undefined") {
          this.$router.push("/auction").catch(() => {
            "Customer Profile";
          });
        } else if (typeof this.userinfo.sellerId != "undefined") {
          this.$router.push("/vendorprofile").catch(() => {
            "Vendor Profile";
          });
        }
      }
    }
  },
  methods: {
    changeType() {
      this.passwordCls = this.passwordCls == "password" ? "text" : "password";
    },
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/customerlogin", user).then(
        () => {
          this.$router.push("/auction");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.Message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
