import axios from 'axios';
import config from '../config';
const API_URL = config.BASE_URL;

class AuthService {
  customerlogin(user) {
    return axios
      .post(API_URL, {
        sp: 'getCustomerAuth',
        username: user.username,
        password: user.password
      })
      .then(response => {
       if(response.data.Data){
        localStorage.setItem('user', JSON.stringify(response.data.Data[0]));
       }
        
        return response.data.Data[0];

       
      });
  }
  vendorlogin(user) {
    return axios
      .post(API_URL, {
        sp: 'getSellerAuth',
        username: user.username,
        password: user.password
      })
      .then(response => {
        if(response.data.Data){
        localStorage.setItem('user', JSON.stringify(response.data.Data[0])); 
      }
        return response.data.Data[0];
      });
  }

  logout() {
    localStorage.removeItem('user');
    this.userRole = "";
  }

  customerregister(user) {
    // console.log(user);
    var isTrueSet = (user.acceptTerms === 'true');
    return axios.post(API_URL, {
      sp: 'insCustomer',
      fullName: user.firstName +" "+ user.lastName,
      email: user.email,
      phone: user.phone,
      password: user.password,
      idNumber:user.idnumber,
      idProof: user.image,
      status:1,
      termsChecked: isTrueSet
    });
  }
  vendorregister(user) {
    var isTrueSet = (user.acceptTerms === 'true');
    //  console.log(user);

  
      let payload ={
        "sp": 'insSeller',
        "sellerName": user.firstName +" "+ user.lastName,
        "sellerEmail": user.email,
        "sellerPhone": user.phone,
        "sellerPassword": user.password,
        "idProofImage": user.image,
        "gstNumber": user.idnumber,
        "termsChecked": isTrueSet

      }
      // console.log(payload);
     
    return axios.post(API_URL,payload );
  }

}


export default new AuthService();