<template>
  <navbar></navbar>
  <div>
    <main class="page-content">
      <div class="account-page-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-3 account_border-right">
              <sideMenuVendor></sideMenuVendor>
            </div>
            <div class="col-lg-9">
              <div>
                <div class="progress-bar-custom">
                  <div
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :class="{
                      active: activeTab === index,
                      visited: index < activeTab,
                    }"
                    class="progress-step-custom"
                    :style="{ width: stepWidth + '%' }"
                  >
                    {{ tab }}
                  </div>
                </div>

                <div class="tabs">
                  <div
                    v-for="(tab, index) in tabs"
                    :key="index"
                    :class="{ active: activeTab === index }"
                    @click="activeTab = index"
                    class="tab"
                  >
                    {{ tab }}
                  </div>
                </div>

                <div class="tab-content">
                  <div v-show="activeTab === 0">
                    <!-- Content for Tab 1 -->
                    <h2>Tab 1 Content</h2>
                    <input
                      type="text"
                      v-model="tab1Data"
                      placeholder="Enter data"
                    />
                    <button @click="saveTabData(0)">Save</button>
                    <button @click="nextTab(1)">Next</button>
                  </div>

                  <div v-show="activeTab === 1">
                    <!-- Content for Tab 2 -->
                    <h2>Tab 2 Content</h2>
                    <textarea
                      v-model="tab2Data"
                      placeholder="Enter data"
                    ></textarea>
                    <button @click="saveTabData(1)">Save</button>
                    <button @click="prevTab()">Previous</button>
                    <button @click="nextTab(2)">Next</button>
                  </div>

                  <div v-show="activeTab === 2">
                    <!-- Content for Tab 3 -->
                    <h2>Tab 3 Content</h2>
                    <input
                      type="email"
                      v-model="tab3Data"
                      placeholder="Enter email"
                    />
                    <button @click="saveTabData(2)">Save</button>
                    <button @click="prevTab()">Previous</button>
                    <button @click="submitForm()">Submit</button>
                  </div>
                  <div v-show="activeTab === 3">
                    <!-- Content for Tab 3 -->
                    <h2>Tab 4 Content</h2>
                    <input
                      type="email"
                      v-model="tab3Data"
                      placeholder="Enter email"
                    />
                    <button @click="saveTabData(3)">Save</button>
                    <button @click="prevTab()">Previous</button>
                    <button @click="submitForm()">Submit</button>
                  </div>

                  <div v-show="activeTab === 4">
                    <!-- Content for Tab 3 -->
                    <h2>Tab 5 Content</h2>
                    <input
                      type="email"
                      v-model="tab3Data"
                      placeholder="Enter email"
                    />
                    <button @click="saveTabData(4)">Save</button>
                    <button @click="prevTab()">Previous</button>
                    <button @click="submitForm()">Submit</button>
                  </div>
                  <div v-show="activeTab === 5">
                    <!-- Content for Tab 3 -->
                    <h2>Tab 6 Content</h2>
                    <input
                      type="email"
                      v-model="tab3Data"
                      placeholder="Enter email"
                    />
                    <button @click="saveTabData(5)">Save</button>
                    <button @click="prevTab()">Previous</button>
                    <button @click="submitForm()">Submit</button>
                  </div>
                  <div v-show="activeTab === 6">
                    <!-- Content for Tab 3 -->
                    <h2>Tab 7 Content</h2>
                    <input
                      type="email"
                      v-model="tab3Data"
                      placeholder="Enter email"
                    />
                    <button @click="saveTabData(6)">Save</button>
                    <button @click="prevTab()">Previous</button>
                    <button @click="submitForm()">Submit</button>
                  </div>
                  <!-- Add more tab content sections as needed -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import sideMenuVendor from "@/components/sideMenuVendor.vue";
import navbar from "@/components/navbar.vue";
export default {
  components: {
    navbar,
    sideMenuVendor,
  },
  data() {
    return {
      activeTab: 0,
      tabs: ["Tab 1", "Tab 2", "Tab 3","Tab 4","Tab 5","Tab 6","Tab 7"], // Add more tab names as needed
      tab1Data: "",
      tab2Data: "",
      tab3Data: "",
      tab4Data: "",
      tab5Data: "",
      tab6Data: "",
      tab7Data: "",
      lastTabIndex: 6, // Update this value if you add more tabs
      formData: {},
      stepWidth: 0,
    };
  },
  mounted() {
    this.updateProgressBar();
  },
  methods: {
    updateProgressBar() {
      this.stepWidth = (100 / this.tabs.length) * this.activeTab;
    },
    saveTabData(index) {
      // Save data for the current tab without validation
      switch (index) {
        case 0:
          this.formData.tab1Data = this.tab1Data;
          console.log("Saving data for Tab 1:", this.tab1Data);
          break;

        case 1:
          this.formData.tab2Data = this.tab2Data;
          console.log("Saving data for Tab 2:", this.tab2Data);
          break;

        case 2:
          this.formData.tab3Data = this.tab3Data;
          console.log("Saving data for Tab 3:", this.tab3Data);
          break;

        // Add more cases for additional tabs

        default:
          break;
      }
    },
    prevTab() {
      if (this.activeTab > 0) {
        this.activeTab--;
        this.updateProgressBar();
      }
    },
    nextTab(index) {
      if (this.activeTab < this.lastTabIndex) {
        this.activeTab = index;
        this.updateProgressBar();
      }
    },
    submitForm() {
      // Validate all tabs before submitting
      for (let i = 0; i <= this.lastTabIndex; i++) {
        if (!this.validateTab(i)) {
          this.activeTab = i;
          this.updateProgressBar();
          return;
        }
      }

      // If all tabs are valid, log the form data
      console.log("Form Data:", this.formData);
    },
    validateTab(index) {
      switch (index) {
        case 0:
          if (this.tab1Data.trim() === "") {
            alert("Please enter data for Tab 1");
            return false;
          }
          break;

        case 1:
          if (this.tab2Data.trim() === "") {
            alert("Please enter data for Tab 2");
            return false;
          }
          break;

        case 2:
          if (this.tab3Data.trim() === "") {
            alert("Please enter data for Tab 3");
            return false;
          }
          break;

        // Add more cases for additional tabs

        default:
          break;
      }

      return true;
    },
  },
};
</script>

<style scoped>
.progress-bar-custom {
  display: flex;
  position: relative;
  margin-bottom: 10px;
  background-color: #ccc !important;
  flex-direction: row !important;
}

.progress-step-custom {
  flex: 1;
  text-align: center;
  padding: 10px;
  /* background-color: #ccc; */
  position: relative;
  border-radius: 50px;
  z-index: 1;
}

.progress-step-custom.active {
  background-color: #cab4ff;
}

.progress-step-custom.visited {
  background-color: #eaeaea;
}

.progress-line {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 4px;
  background-color: #dd3c3c;
  z-index: 0;
}

.tabs {
  display: flex;
  margin-bottom: 10px;
}

.tab {
  padding: 10px;
  background-color: #ccc;
  cursor: pointer;
}

.tab.active {
  background-color: #f9f9f9;
}

.tab-content {
  display: block;
}
</style>
