<template>
  <div>
    <Carousel :settings="settings" v-if="mainBanner">
      <Slide v-for="banner in mainBanner" :key="banner.teid">
        <div class="carousel__item">
          <router-link :to="banner.Link">
            <img :src="path + banner.imageUrl" />
          </router-link>
        </div>
      </Slide>

      <template #addons>
        <!-- <Navigation /> -->
        <!-- <Pagination /> -->
      </template>
    </Carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide/*, Navigation ,Pagination */} from "vue3-carousel";
import Config from "@/config.js";
// import userService from "@/services/user.service";
export default {
  name: "MainSlider",
  components: {
    Carousel,
    Slide,
    // Pagination,
    // Navigation,
  },
  props: {
    mainBanner: Array,
  },

  data() {
    return {
      path: Config.BASE_URL_IMG,
      // carousel settings
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        autoplay: 5000,
        wrapAround: false,
      },
      
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      // breakpoints: {
      //   // 700px and up
      //   700: {
      //     itemsToShow: 3.5,
      //     snapAlign: "center",
      //   },
      //   // 1024 and up
      //   1024: {
      //     itemsToShow: 4,
      //     snapAlign: "start",
      //   },
      // },

      Mainbannerlist: "",
    };
  },
  // mounted() {
  //   this.getBanners();
  // },

  // methods: {
  //   getBanners() {
  //     userService
  //       .getBanners()
  //       .then((response) => {
  //         this.Mainbannerlist = response.data.Data;
         
  //         // for (let index = 0; index < this.Mainbannerlist.length; index++) {
  //         //   const element = this.Mainbannerlist[index];
  //         //   console.log(element.imageUrl);
  //         // }
  //       })
  //       .catch((e) => {
  //         console.log(e);
  //       });
  //   },
  // },
};
</script>
